import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  add_to_card,
  add_to_wishlist,
  messageClear,
} from '../../store/reducers/cardReducer';
import toast from 'react-hot-toast';
import ProductCard from './ProductCard';
import './FeatureProducts.css';

const FeatureProducts = ({ products }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const { errorMessage, successMessage } = useSelector((state) => state.card);
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsPerPage = 2;
  const add_card = (id) => {
    if (userInfo) {
      dispatch(
        add_to_card({
          userId: userInfo.id,
          quantity: 1,
          productId: id,
        })
      );
    } else {
      navigate('/login');
    }
  };

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
      dispatch(messageClear());
    }
    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(messageClear());
    }
  }, [successMessage, errorMessage]);

  const add_wishlist = (pro) => {
    dispatch(
      add_to_wishlist({
        userId: userInfo.id,
        productId: pro._id,
        name: pro.name,
        price: pro.price,
        image: pro.images[0],
        discount: pro.discount,
        rating: pro.rating,
        slug: pro.slug,
      })
    );
  };

  return (
    <div className="featuringContainer">
      <div className="heading">
        <h2>
          Featured <span className="headingText">FSBO Packages</span>{' '}
        </h2>
        <p className="headingDesc">
          Explore our top FSBO packages tailored for every seller, from
          first-timers to those seeking full-service support. These featured
          packages offer exceptional tools and guidance to empower you through
          every stage of selling your property. Discover a range of options,
          from the Starter package with essential resources to the Premium
          package, including professional photography, virtual tours, and full
          transaction management. Each package has been crafted to help you
          reach a broader audience, present your property effectively, and close
          the sale smoothly, all while maintaining control over the process.
        </p>
      </div>

      <div className="productContainer">
        {products
          .slice(currentIndex, currentIndex + itemsPerPage)
          .map((p, i) => (
            <div className="productCard">
              <ProductCard
                key={i}
                product={p}
                addCard={add_card}
                addWishlist={add_wishlist}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default FeatureProducts;
