import React from 'react';
import Carousel from 'react-multi-carousel';
import { Link } from 'react-router-dom';
import 'react-multi-carousel/lib/styles.css';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

const Products = ({ title, products }) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const ButtonGroup = ({ next, previous }) => {
    return (
      <div className="flex justify-between items-center">
        <div className="text-xl font-bold text-slate-600"> {title} </div>
        <div className="flex justify-center items-center gap-3 text-slate-600">
          <button
            onClick={previous}
            className="w-[40px] h-[40px] flex justify-center items-center bg-slate-300 border border-slate-200 rounded-full hover:bg-sky-400 hover:text-white transition duration-200"
          >
            <IoIosArrowBack />
          </button>
          <button
            onClick={next}
            className="w-[40px] h-[40px] flex justify-center items-center bg-slate-300 border border-slate-200 rounded-full hover:bg-sky-400 hover:text-white transition duration-200"
          >
            <IoIosArrowForward />
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="flex gap-8 flex-col-reverse">
      <Carousel
        autoPlay={false}
        infinite={false}
        arrows={false}
        responsive={responsive}
        transitionDuration={500}
        renderButtonGroupOutside={true}
        customButtonGroup={<ButtonGroup />}
      >
        {products.map((productGroup, i) => (
          <div key={i} className="flex flex-col justify-start gap-2">
            {productGroup.map((product, j) => (
              <Link
                key={j}
                className="flex justify-start items-start transition-transform transform hover:scale-102"
                to="#"
              >
                <div className="relative w-[110px] h-[110px] overflow-hidden rounded-lg shadow-md transition-transform duration-300">
                  <img
                    className="w-full h-full object-cover transition-transform duration-300 transform hover:scale-105"
                    src={product.images[0]}
                    alt={product.name}
                  />
                </div>
                <div className="px-3 flex justify-start items-start gap-1 flex-col text-slate-600">
                  <h2 className="font-semibold">{product.name}</h2>
                  <span className="text-lg font-bold text-sky-500">
                    ${product.price}
                  </span>
                </div>
              </Link>
            ))}
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Products;
