import React, { useEffect, useState } from 'react';
import api from '../api/api';

const PayPal = ({ price, shippingAddress, customerOrderId }) => {
  const [paymentId, setPaymentId] = useState(null);
  const shippingAddressUpdated = {
    recipient_name: shippingAddress.name,
    line1: shippingAddress.address,
    line2: '', // Optional
    city: shippingAddress.city,
    state: shippingAddress.province,
    postal_code: shippingAddress.post,
    admin_area_2: 'New York',
    country_code: 'US',
  };

  // Create PayPal order on button click
  const createOrder = () => {
    api
      .post('/order/create-paypal-order', {
        price,
        shippingAddress: shippingAddressUpdated,
      }) // Adjust to your backend endpoint
      .then((response) => {
        const { orderID, approvalUrl } = response.data;
        setPaymentId(orderID);
        if (approvalUrl) {
          window.location.href = approvalUrl; // Redirect to the PayPal approval page
        }
        // Handle the PayPal order creation response here
        console.log('PayPal Order Created:', response.data);
        return orderID;
      })
      .catch((err) => {
        console.error('Error creating PayPal order:', err);
      });
  };

  // Capture PayPal payment after approval
  const onApprove = (orderID, payerID) => {
    api
      .post('/order/capture-paypal-payment', {
        customerOrderId,
        orderID,
        payerID,
      }) // Adjust to your backend endpoint
      .then((response) => {
        console.log('Payment captured successfully', response.data);
      })
      .catch((err) => {
        console.error('Error capturing PayPal payment:', err);
      });
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const orderID = urlParams.get('orderID');
    const payerID = urlParams.get('payerID');

    if (orderID && payerID) {
      onApprove(orderID, payerID);
    }
  }, []);

  return (
    <div>
      {/* Custom button to trigger PayPal order creation */}
      <button onClick={createOrder} style={styles.button}>
        <img
          src="https://www.paypalobjects.com/webstatic/icon/pp32.png"
          alt="PayPal Logo"
          style={styles.logo}
        />
        <span style={styles.buttonText}>Pay Now</span>
      </button>
      {/* Custom button to capture PayPal payment */}
    </div>
  );
};

// Simple inline styles for the buttons
const styles = {
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    backgroundColor: '#0070ba',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    margin: '10px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    marginLeft: '10px',
  },
  logo: {
    width: '30px',
    height: '30px',
  },
};

export default PayPal;
