import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import './ContactForm.css';
import { contact_submit } from '../store/reducers/homeReducer';

const ContactForm = ({ showHeader }) => {
  const dispatch = useDispatch();
  const { successMessage, errorMessage } = useSelector((state) => state.home);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    question: '',
  });

  const inputHandle = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const submitForm = (e) => {
    e.preventDefault();
    dispatch(contact_submit(formData));
  };

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
    }
    if (errorMessage) {
      toast.error(errorMessage);
    }
  }, [successMessage, errorMessage]);

  useEffect(() => {
    const inputs = document.querySelectorAll('.input');

    const focusFunc = function () {
      let parent = this.parentNode;
      parent.classList.add('focus');
    };

    const blurFunc = function () {
      let parent = this.parentNode;
      if (this.value === '') {
        parent.classList.remove('focus');
      }
    };

    inputs.forEach((input) => {
      input.addEventListener('focus', focusFunc);
      input.addEventListener('blur', blurFunc);
    });

    return () => {
      inputs.forEach((input) => {
        input.removeEventListener('focus', focusFunc);
        input.removeEventListener('blur', blurFunc);
      });
    };
  }, []);

  return (
    <div>
      {showHeader && <Header />}
      <div className="contact-form-container">
        <span className="big-circle"></span>
        <img src="img/shape.png" className="square" alt="" />
        <div className="contact-form">
          <div className="contact-info">
            <h3 className="contact-form-title">Let's get in touch</h3>
            <p className="text">
              We’re excited to hear from you! At DIY, we believe in creating
              seamless connections and exceptional experiences. Whether you’re
              curious about our innovative real estate solutions, need guidance
              on selling your home, or just want to share your thoughts, our
              team is ready to assist you.
            </p>
            <div className="info">
              {/* <div className="information">
                                <i className="fas fa-map-marker-alt"></i>&nbsp;&nbsp;
                                <p>92 Cherry Drive Uniondale, NY 11553</p>
                            </div> */}
              <div className="information">
                <i className="fas fa-envelope"></i>&nbsp;&nbsp;
                <p>support@diyflexrealty.com</p>
              </div>
              <div className="information">
                <i className="fas fa-phone"></i>&nbsp;&nbsp;
                <p>+1 (720) 422-1156 (9am to 6pm Mountain Time, Mon-Sat)</p>
              </div>
            </div>
            <div className="social-media">
              <p>Connect with us :</p>
              <div className="social-icons">
                <a
                  className="icon"
                  href="https://www.facebook.com/people/DIY-Flex-Realty/61566891073607"
                >
                  <FaFacebookF />
                </a>
                <a className="icon" href="#">
                  <FaTwitter />
                </a>
                <a
                  className="icon"
                  href="https://www.instagram.com/diyflexrealty"
                >
                  <FaInstagram />
                </a>
              </div>
            </div>
          </div>

          <div className="contact-form-inner">
            <span className="circle one"></span>
            <span className="circle two"></span>
            <form onSubmit={submitForm} autoComplete="off">
              <h3 className="contact-form-title">Contact us</h3>

              <div className="input-container">
                <input
                  type="text"
                  name="name"
                  className="input"
                  value={formData.name}
                  onChange={inputHandle}
                  required
                />
                <label>Name</label>
                <span>Name</span>
              </div>

              <div className="input-container">
                <input
                  type="email"
                  name="email"
                  className="input"
                  value={formData.email}
                  onChange={inputHandle}
                  required
                />
                <label>Email</label>
                <span>Email</span>
              </div>

              <div className="input-container">
                <input
                  type="tel"
                  name="phoneNumber"
                  className="input"
                  value={formData.phoneNumber}
                  onChange={inputHandle}
                  required
                />
                <label>Phone Number</label>
                <span>Phone Number</span>
              </div>

              <div className="input-container">
                <textarea
                  name="question"
                  className="input"
                  value={formData.question}
                  onChange={inputHandle}
                  required
                ></textarea>
                <label>Question</label>
                <span>Question</span>
              </div>

              <input type="submit" value="Send" className="btn" />
            </form>
          </div>
        </div>
      </div>
      {showHeader && <Footer />}
    </div>
  );
};

export default ContactForm;
