import React, { useState } from 'react';

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: 'Expert Guidance from Start to Finish',
      answer:
        'We’re here to help you navigate the complexities of real estate. From listing your property or finding your dream home to negotiating the deal, we’ll ensure you feel confident and informed every step of the way.',
    },
    {
      question: 'Transparent Communication',
      answer:
        'We’ll clearly explain all terms, options, and steps involved in your transaction. Whether it’s understanding market conditions or navigating offers, we’re committed to keeping you informed and empowered.',
    },
    {
      question: 'Honest Negotiation on Your Behalf',
      answer:
        'We work tirelessly to negotiate the best possible terms for you, including price, contingencies, and closing timelines. Your goals are our top priority, and we’ll advocate for your interests at every turn.',
    },
    {
      question: 'Professional and Accurate Documentation',
      answer:
        'We ensure all contracts, agreements, and disclosures are completed accurately and on time. This includes submitting all necessary paperwork to ensure your transaction stays on track.',
    },
    {
      question: 'Careful Management of Funds',
      answer:
        'If your transaction involves escrow deposits or other funds, we handle these with the utmost care, ensuring they are securely managed in compliance with legal requirements.',
    },
    {
      question: 'Compliance with Laws and Ethics',
      answer:
        'As licensed professionals, we strictly adhere to all state and federal real estate laws, ensuring your transaction is conducted with integrity and fairness.',
    },
    {
      question: 'Support Beyond the Sale',
      answer:
        'Our relationship doesn’t end at closing! We’re always here to answer your questions and assist with your future real estate needs.',
    },
    {
      question: 'Why Choose DIY Flex Realty',
      answer: (
        <ul className="list-disc pl-6 space-y-2">
          <li>Experience: Decades of industry expertise.</li>
          <li>Transparency: Clear, open, and honest communication.</li>
          <li>Commitment: Your goals are our top priority.</li>
        </ul>
      ),
    },
  ];

  return (
    <div className="w-[90%] mx-auto mt-10 p-4">
      <div className="w-[100%] text-left text-[2rem] font-bold font-poppins text-black pb-9">
        <h2>
          Our Commitment to You:{' '}
          <span className="text-[#37AFE1]">
            {' '}
            What We Do as Your Real Estate Broker
          </span>
        </h2>
        <p className="text-[#000000b3] text-[1rem] text-base mt-8 tracking-wider w-[70%] sm:w-full md:w-full font-normal font-poppins">
          At DIY Flex Realty, we’re dedicated to making your real estate journey
          seamless, stress-free, and successful. Here’s how we support you
          through every step of the process:
        </p>
      </div>
      {faqData.map((item, index) => (
        <div key={index} className="border-b border-gray-300 w-full">
          <button
            onClick={() => toggleAccordion(index)}
            className="w-full text-left py-4 px-4 flex justify-between items-center bg-gray-100 hover:bg-gray-200 transition-colors duration-300 focus:outline-none"
          >
            <span className="text-base font-poppins md:text-lg font-semibold text-gray-800">
              {item.question}
            </span>
            <span
              className={`text-gray-500 transform transition-transform duration-300 ${
                activeIndex === index ? '' : 'rotate-180'
              }`}
            >
              <svg
                className="w-5 h-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
            </span>
          </button>
          <div
            className={`${
              activeIndex === index ? 'max-h-screen p-4' : 'max-h-0'
            } overflow-hidden transition-all duration-300 bg-white text-gray-700`}
          >
            <p className="text-md w-[60%] sm:w-full md-w-full font-poppins md:text-base">
              {item.answer}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Faq;
