import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get_orders } from '../../store/reducers/orderReducer';
import api from '../../api/api';
import './Uploads.css';
import toast from 'react-hot-toast';

const Uploads = () => {
  const [state, setState] = useState('all');
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const { myOrders } = useSelector((state) => state.order);

  const handleUpload = async (orderId, productId, event) => {
    const file = event.target.files[0];
    if (!file) {
      console.error('No file selected');
      return;
    }

    const formData = new FormData();
    formData.append('pdfFile', file);
    formData.append('orderId', orderId);
    formData.append('productId', productId);

    try {
      await api.post('/home/customer/upload-pdf', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch(get_orders({ status: state, customerId: userInfo.id }));
      toast.success('Document uploaded successfully!');
    } catch (error) {
      console.error(
        'Error uploading PDF:',
        error.response ? error.response.data : error.message
      );
      toast.error('Failed to upload document. Please try again.');
    }
  };

  useEffect(() => {
    dispatch(get_orders({ status: state, customerId: userInfo.id }));
  }, [state]);

  return (
    <div className="bg-white p-4 rounded-lg shadow-md">
      <div className="flex justify-between items-center pb-4">
        <h2 className="text-2xl font-semibold text-slate-700">My Uploads</h2>
      </div>
      <div className="w-full p-4 rounded-md">
        <div className="relative overflow-x-auto mt-5">
          <table className="w-full text-sm text-left text-[#d0d2d6] border-collapse">
            <thead className="text-sm text-[#d0d2d6] uppercase bg-gray-200 border-b border-gray-400">
              <tr className="font-semibold text-gray-700 bg-gray-200 py-3">
                <th scope="col" className="py-3 px-4">
                  Package
                </th>
                <th scope="col" className="py-3 px-4">
                  Download PDF
                </th>
                <th scope="col" className="py-3 px-4">
                  Upload PDF
                </th>
                <th scope="col" className="py-3 px-4">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {myOrders
                ?.filter((o) =>
                  o.products.some(
                    (product) =>
                      product.editablePDFUrls &&
                      product.editablePDFUrls.length > 0
                  )
                )
                ?.map((order, orderIndex) =>
                  order?.products?.map((product, productIndex) => {
                    const productUploads = order.uploads?.filter(
                      (upload) => upload.id === product._id
                    );
                    const isNotApproved = productUploads?.some(
                      (upload) => upload.status !== 'Approved'
                    );
                    const isRejected = productUploads?.some(
                      (upload) => upload.status == 'Rejected'
                    );
                    return (
                      <tr
                        key={`${orderIndex}-${productIndex}`}
                        className="text-sm text-gray-600 border-b border-gray-300"
                      >
                        {/* Package Name */}
                        <td className="py-10 px-2 font-medium whitespace-nowrap">
                          {product?.name}
                        </td>

                        {/* Download PDF Button */}
                        <td className="py-1 px-4">
                          <div className="flex flex-col items-start space-y-2">
                            {product?.editablePDFUrls?.map(
                              (downloadItem, downloadIndex) => (
                                <a
                                  key={downloadIndex}
                                  href={downloadItem}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="p-2 bg-blue-500 text-white rounded hover:shadow-lg hover:shadow-blue-500/50"
                                >
                                  Download Form
                                </a>
                              )
                            )}
                          </div>
                        </td>

                        {/* Upload PDF Button */}
                        <td className="py-1 px-4">
                          <div className="flex flex-col items-start space-y-2">
                            {product?.editablePDFUrls?.map((_, uploadIndex) => (
                              <label
                                key={uploadIndex}
                                className="p-2 bg-blue-500 text-white rounded hover:shadow-lg hover:shadow-blue-500/50"
                              >
                                <input
                                  type="file"
                                  accept="application/pdf"
                                  onChange={(event) =>
                                    handleUpload(order._id, product._id, event)
                                  }
                                  className="hidden"
                                />
                                <span>Upload PDF</span>
                              </label>
                            ))}
                          </div>
                        </td>

                        {/* Status */}
                        <td
                          className={`py-1 px-4 font-semibold ${
                            order?.uploads?.length > 0
                              ? isNotApproved
                                ? isRejected
                                  ? 'text-red-500'
                                  : 'text-blue-500'
                                : 'text-green-500'
                              : 'text-black'
                          }`}
                        >
                          {order?.uploads?.length > 0
                            ? isNotApproved
                              ? isRejected
                                ? 'Rejected'
                                : 'Pending'
                              : 'Approved'
                            : 'Not Uploaded Yet'}
                        </td>
                      </tr>
                    );
                  })
                )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Uploads;
