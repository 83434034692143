import React from "react";

const AffiliateButton = ({ platform, url }) => {
  const platformData = {
    Fiverr: {
      backgroundColor: "#1DBF73",
      color: "#fff"
    },
    default: {
      backgroundColor: "#ddd",
      color: "#000",
      logo: "",
    },
  };

  const { backgroundColor, color, logo } = platformData[platform] || platformData.default;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor,
        color,
        padding: "10px 15px",
        borderRadius: "10px",
        cursor: "pointer",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        textDecoration: "none",
        transition: "transform 0.2s",
      }}
      onClick={() => window.open(url, "_blank")}
      onMouseOver={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
      onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
    >
      {logo && (
        <img
          src={logo}
          alt={`${platform} logo`}
          style={{
            width: "40px", // Increased width
            height: "40px", // Increased height
            marginRight: "15px", // More spacing
            borderRadius: "5px", // Rounded logo edges
            objectFit: "contain",
          }}
        />
      )}
      <span style={{ fontSize: "18px", fontWeight: "bold" }}>{platform} Affiliate Link</span>
    </div>
  );
};

export default AffiliateButton;
