import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import './ContactForm.css';
import { lead_submit } from '../store/reducers/homeReducer';

const LeadForm = () => {
  const dispatch = useDispatch();
  const { successMessage, errorMessage } = useSelector((state) => state.home);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    line1: '',
    line2: '',
    city: '',
    state: '',
    zip: '',
  });

  const inputHandle = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const submitForm = (e) => {
    e.preventDefault();
    dispatch(lead_submit(formData));
  };

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
    }
    if (errorMessage) {
      toast.error(errorMessage);
    }
  }, [successMessage, errorMessage]);

  useEffect(() => {
    const inputs = document.querySelectorAll('.input');

    const focusFunc = function () {
      let parent = this.parentNode;
      parent.classList.add('focus');
    };

    const blurFunc = function () {
      let parent = this.parentNode;
      if (this.value === '') {
        parent.classList.remove('focus');
      }
    };

    inputs.forEach((input) => {
      input.addEventListener('focus', focusFunc);
      input.addEventListener('blur', blurFunc);
    });

    return () => {
      inputs.forEach((input) => {
        input.removeEventListener('focus', focusFunc);
        input.removeEventListener('blur', blurFunc);
      });
    };
  }, []);

  return (
    <div>
      <Header />
      <div className="contact-form-container">
        <div className="contact-form">
          <div className="contact-info">
            <h3 className="contact-form-title">Let's make you an offer</h3>
            <p className="text">
              Ready to sell your property? Let us know a few details about it,
              and we’ll provide you with a competitive offer. Our team is here
              to make the process simple and straightforward.
            </p>
          </div>

          <div className="contact-form-inner">
            <form onSubmit={submitForm} autoComplete="off">
              <h3 className="contact-form-title">Property Information</h3>

              <div className="input-container">
                <input
                  type="text"
                  name="name"
                  className="input"
                  value={formData.name}
                  onChange={inputHandle}
                  required
                />
                <label>Owner's Name</label>
                <span>Owner's Name</span>
              </div>

              <div className="input-container">
                <input
                  type="email"
                  name="email"
                  className="input"
                  value={formData.email}
                  onChange={inputHandle}
                  required
                />
                <label>Email</label>
                <span>Email</span>
              </div>

              <div className="input-container">
                <input
                  type="tel"
                  name="phoneNumber"
                  className="input"
                  value={formData.phoneNumber}
                  onChange={inputHandle}
                  required
                />
                <label>Phone Number</label>
                <span>Phone Number</span>
              </div>

              <div className="input-container">
                <input
                  type="text"
                  name="line1"
                  className="input"
                  value={formData.line1}
                  onChange={inputHandle}
                  required
                />
                <label>Property Address (Line 1)</label>
                <span>Property Address (Line 1)</span>
              </div>

              <div className="input-container">
                <input
                  type="text"
                  name="line2"
                  className="input"
                  value={formData.line2}
                  onChange={inputHandle}
                />
                <label>Address Line 2</label>
                <span>Address Line 2</span>
              </div>

              <div className="input-row">
                <div className="input-container half-width">
                  <input
                    type="text"
                    name="city"
                    className="input"
                    value={formData.city}
                    onChange={inputHandle}
                    required
                  />
                  <label>City</label>
                  <span>City</span>
                </div>
                <div className="input-container half-width">
                  <input
                    type="text"
                    name="state"
                    className="input"
                    value={formData.state}
                    onChange={inputHandle}
                    required
                  />
                  <label>State</label>
                  <span>State</span>
                </div>
              </div>

              <div className="input-container">
                <input
                  type="text"
                  name="zip"
                  className="input"
                  value={formData.zip}
                  onChange={inputHandle}
                  required
                />
                <label>ZIP Code</label>
                <span>ZIP Code</span>
              </div>

              <button type="submit" className="btn">
                Get Offer
              </button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LeadForm;
