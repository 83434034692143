import React, { useState } from 'react';
import Header from './../components/Header';
import Footer from './../components/Footer';
import { useLocation } from 'react-router-dom';
import Stripe from '../components/Stripe';
import Paypal from '../components/Paypal';

const Payment = () => {
  const {
    state: { price, items, orderId, shippingInfo },
  } = useLocation();
  const [paymentMethod, setPaymentMethod] = useState('stripe');

  return (
    <div>
      <Header />
      <section className="bg-[#eeeeee]">
        <div className="w-[85%] lg:w-[90%] md:w-[90%] sm:w-[90%] mx-auto py-16 mt-4 ">
          <div className="flex flex-wrap md:flex-col-reverse">
            <div className="w-7/12 md:w-full">
              <div className="pr-2 md:pr-0">
                <div className="flex justify-between w-full">
                  {/* Stripe Button */}
                  <div
                    onClick={() => setPaymentMethod('stripe')}
                    className={`flex-1 border-r cursor-pointer py-8 ${paymentMethod === 'stripe' ? 'bg-white' : 'bg-slate-100'} flex flex-col gap-[3px] justify-center items-center`}
                  >
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Stripe_Logo%2C_revised_2016.svg/2560px-Stripe_Logo%2C_revised_2016.svg.png"
                      alt="Stripe Logo"
                      className="w-[50px] h-[24px]"
                    />
                    <span className="text-slate-600">Stripe</span>
                  </div>

                  {/* PayPal Button */}
                  <div
                    onClick={() => setPaymentMethod('paypal')}
                    className={`flex-1 border-r cursor-pointer py-8 ${paymentMethod === 'paypal' ? 'bg-white' : 'bg-slate-100'} flex flex-col gap-[3px] justify-center items-center`}
                  >
                    <img
                      src="https://www.paypalobjects.com/webstatic/icon/pp32.png"
                      alt="PayPal Logo"
                      className="w-[40px] h-[40px]"
                    />
                    <span className="text-slate-600">PayPal</span>
                  </div>

                  {/* COD Button */}
                  {/* <div
                    onClick={() => setPaymentMethod('cod')}
                    className={`flex-1 border-r cursor-pointer py-8 ${paymentMethod === 'cod' ? 'bg-white' : 'bg-slate-100'} flex flex-col gap-[3px] justify-center items-center`}
                  >
                    <img
                      src="https://i.pinimg.com/originals/7c/10/a2/7c10a2a122ffb1b09ea1cbb188923507.jpg"
                      alt="COD Logo"
                      className="w-[50px] h-[40px]"
                    />
                    <span className="text-slate-600">COD</span>
                  </div> */}
                </div>

                {paymentMethod === 'stripe' && (
                  <div>
                    <Stripe orderId={orderId} price={price} />
                  </div>
                )}
                {paymentMethod === 'paypal' && (
                  <div>
                    <Paypal
                      customerOrderId={orderId}
                      price={price}
                      shippingAddress={shippingInfo}
                    />
                  </div>
                )}
                {paymentMethod === 'cod' && (
                  <div className="w-full px-4 py-8 bg-white shadow-sm">
                    <button className="px-10 py-[6px] rounded-sm hover:shadow-green-500/20 hover:shadow-lg bg-[#37AFE1] text-white">
                      Pay Now
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className="w-5/12 md:w-full">
              <div className="pl-2 md:pl-0 md:mb-0">
                <div className="bg-white shadow p-5 text-slate-600 flex flex-col gap-3">
                  <h2 className="font-bold text-lg">Order Summary </h2>
                  <div className="flex justify-between items-center">
                    <span>{items} Items and Platform Fee Included </span>
                    <span>${price} </span>
                  </div>
                  <div className="flex justify-between items-center font-semibold">
                    <span>Total Amount </span>
                    <span className="text-lg text-green-600">${price}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Payment;
