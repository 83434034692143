import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get_orders } from "../../store/reducers/orderReducer";
import api from "../../api/api";
import toast from "react-hot-toast";

const TransactionManager = () => {
  const [state, setState] = useState("all");
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const { customer } = useSelector((state) => state.order);

  useEffect(() => {
    dispatch(get_orders({ status: state, customerId: userInfo.id }));
  }, [state, dispatch, userInfo.id]);

  const groupedDocuments = customer?.legalDocuments?.reduce((acc, doc) => {
    if (!acc[doc.type]) acc[doc.type] = [];
    acc[doc.type].push(doc);
    return acc;
  }, {});

  const handleUpload = async (legalDocumentName, event) => {
    const file = event.target.files[0];
    if (!file) {
      toast.error("No file selected!");
      return;
    }

    if (file.size > 10 * 1024 * 1024) {
      toast.error("File size exceeds 10MB!");
      return;
    }

    const formData = new FormData();
    formData.append("customerId", customer._id);
    formData.append("legalDocumentName", legalDocumentName);
    formData.append("pdfFile", file);

    try {
      const response = await api.post("/home/customer/legal-upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      toast.success("Document uploaded successfully!");
      dispatch(get_orders({ status: state, customerId: userInfo.id })); // Refresh data
    } catch (error) {
      console.error("Upload Error:", error.response?.data || error.message);
      toast.error("Failed to upload document. Please try again.");
    }
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow-md">
      <div className="flex justify-between items-center pb-4">
        <h2 className="text-2xl font-semibold text-slate-700">
          Transaction Manager
        </h2>
      </div>
      <div className="w-full p-4 rounded-md">
        {groupedDocuments &&
          Object.keys(groupedDocuments).map((type) => (
            <div key={type} className="mb-8">
              <h3 className="text-xl font-bold text-gray-700 mb-4">
                {type} Documents
              </h3>
              <div className="relative overflow-x-auto mt-5">
                <table
                  className="w-full text-sm text-left text-[#d0d2d6] border-collapse table-fixed"
                  style={{ tableLayout: "fixed" }}
                >
                  <thead className="text-sm text-[#d0d2d6] uppercase bg-gray-200 border-b border-gray-400">
                    <tr className="font-semibold text-gray-700 bg-gray-200 py-3">
                      <th
                        scope="col"
                        className="py-3 px-4"
                        style={{ width: "65%" }}
                      >
                        Document Name
                      </th>
                      <th
                        scope="col"
                        className="py-3 px-4"
                        style={{ width: "10%" }}
                      >
                        Download
                      </th>
                      <th
                        scope="col"
                        className="py-3 px-4"
                        style={{ width: "10%" }}
                      >
                        Upload
                      </th>
                      <th
                        scope="col"
                        className="py-3 px-4"
                        style={{ width: "15%" }}
                      >
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {groupedDocuments[type].map((doc, docIndex) => (
                      <tr
                        key={docIndex}
                        className="text-sm text-gray-600 border-b border-gray-300"
                      >
                        <td className="py-4 px-2 font-medium whitespace-nowrap">
                          {doc.name}
                        </td>
                        <td className="py-4 px-4">
                          <a
                            href={doc.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="p-2 bg-blue-500 text-white rounded hover:shadow-lg hover:shadow-blue-500/50"
                          >
                            Download
                          </a>
                        </td>
                        <td className="py-4 px-4">
                          <label className="p-2 bg-blue-500 text-white rounded hover:shadow-lg hover:shadow-blue-500/50">
                            <input
                              type="file"
                              accept="application/pdf"
                              className="hidden"
                              onChange={(event) => handleUpload(doc.name, event)}
                            />
                            <span>Upload</span>
                          </label>
                        </td>
                        <td
                          className={`py-4 px-4 font-semibold ${
                            doc.status === "Approved"
                              ? "text-green-500"
                              : doc.status === "Rejected"
                              ? "text-red-500"
                              : doc.status === "Pending"
                              ? "text-blue-500"
                              : "text-black"
                          }`}
                        >
                          {doc.status === "draft"
                            ? "Not Submitted"
                            : doc.status || "Not Submitted"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default TransactionManager;
