import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { FaFacebookF, FaGoogle } from 'react-icons/fa6';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { customer_register, messageClear } from '../store/reducers/authReducer';
import toast from 'react-hot-toast';
import { FadeLoader } from 'react-spinners';
import './Register.css';

const Register = () => {
  const navigate = useNavigate();
  const { loader, errorMessage, successMessage, userInfo } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();

  const [state, setState] = useState({
    name: '',
    email: '',
    password: '',
  });

  const inputHandle = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const register = (e) => {
    e.preventDefault();
    dispatch(customer_register(state));
  };

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
      dispatch(messageClear());
    }
    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(messageClear());
    }
    if (userInfo) {
      navigate('/');
    }
  }, [successMessage, errorMessage]);

  return (
    <div>
      {loader && (
        <div className="w-screen h-screen flex justify-center items-center fixed left-0 top-0 bg-[#38303033] z-[999]">
          <FadeLoader />
        </div>
      )}

      <Header />
      <div className="register-container">
        <div className="register-form-container">
          <div className="form-section">
            <h2 className="form-title">Register As Seller</h2>
            <form onSubmit={register} className="form">
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  onChange={inputHandle}
                  value={state.name}
                  className="form-input"
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  onChange={inputHandle}
                  value={state.email}
                  className="form-input"
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  onChange={inputHandle}
                  value={state.password}
                  className="form-input"
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Password"
                  required
                />
              </div>
              <button className="submit-button">Register</button>
            </form>
            <div className="separator">
              <div className="separator-line"></div>
              <span className="separator-text">Or</span>
              <div className="separator-line"></div>
            </div>
            <a
              target="_blank"
              href={`${process.env.REACT_APP_DASHBOARD_URL}/login`}
            >
              <div className="external-link seller-login">
                Login As Vendor
              </div>
            </a>
            <a
              target="_blank"
              href={`${process.env.REACT_APP_DASHBOARD_URL}/register`}
            >
              <div className="external-link seller-register">
                Register As a Vendor
              </div>
            </a>
            <div className="text-center text-slate-600 pt-1">
              <p>
                You Already have Account?{' '}
                <Link className="text-blue-500" to="/login">
                  {' '}
                  Login
                </Link>
              </p>
            </div>
          </div>
          <div className="image-section">
            <img
              src="https://static.wixstatic.com/media/1bb613_555f8898f1d9463cbc354fc5c6c93f13f000.jpg/v1/fill/w_320,h_542,al_c,q_80,usm_0.33_1.00_0.00,enc_auto/1bb613_555f8898f1d9463cbc354fc5c6c93f13f000.jpg"
              alt="Register"
              className="image"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Register;
