import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const About = () => {
  return (
    <div>
    <Header />
    <div className="bg-gray-50 min-h-screen py-10">
      <div className="max-w-6xl mx-auto px-4">
        <div className="text-center mb-10">
          <h1 className="text-4xl font-bold text-gray-800 mb-4">About Us</h1>
          <p className="text-lg text-gray-600">
            Empowering you to take control of your real estate journey.
          </p>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            Welcome to DIY Flex Realty
          </h2>
          <p className="text-gray-700 leading-relaxed mb-6">
            At DIY Flex Realty, we empower you to take control of your real
            estate journey. Whether you're buying, selling, or investing, our
            mission is to provide you with the tools, resources, and expert
            guidance you need to navigate the real estate process with
            confidence and flexibility.
          </p>

          <p className="text-gray-700 leading-relaxed mb-6">
            We believe in a modern approach to real estate—one that puts the
            power back in your hands. Our innovative platform combines
            cutting-edge technology with personalized support to help you
            achieve your goals on your terms. We’re here to simplify the
            process, save you time and money, and ensure your experience is as
            seamless as possible.
          </p>

          <div>
            <h3 className="text-xl font-semibold text-gray-800 mb-4">
              What Makes Us Different?
            </h3>
            <ul className="list-disc list-inside text-gray-700 leading-relaxed">
              <li className="mb-2">
                <strong>Empowerment:</strong> We provide you with step-by-step
                guidance, expert tips, and the flexibility to choose the level
                of support you need.
              </li>
              <li className="mb-2">
                <strong>Transparency:</strong> From fees to processes, we
                believe in clear, upfront communication every step of the way.
              </li>
              <li className="mb-2">
                <strong>Value-Driven Solutions:</strong> Our hybrid model
                combines the best of traditional real estate expertise with
                innovative DIY tools to maximize your outcomes.
              </li>
            </ul>
          </div>

          <div className="mt-8">
            <p className="text-gray-700 leading-relaxed">
              Whether you’re a seasoned investor or a first-time buyer, DIY Flex
              Realty is here to support you at every stage of your real estate
              journey. Join us in reshaping how real estate is done, one
              successful transaction at a time.
            </p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    </div>
  );
};

export default About;
