import React, { useEffect, useState } from 'react';
import axios from 'axios';

const EducationLibrary = () => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/home/videos/youtube`
        );
        setVideos(response.data.items || []);
      } catch (error) {
        console.error('Error fetching YouTube videos:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchVideos();
  }, []);

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  return (
    <div className="bg-white p-4 rounded-md">
      <h2 className="text-xl font-semibold text-slate-600">Education Library</h2>
      <div className="pt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {videos.length > 0 ? (
          videos.map((video) => (
            <div
              key={video.id.videoId}
              className="bg-gray-100 p-4 rounded-md shadow-md"
            >
              <a
                href={`https://www.youtube.com/watch?v=${video.id.videoId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={video.snippet.thumbnails.medium.url}
                  alt={video.snippet.title}
                  className="rounded-md mb-2"
                />
                <h3 className="text-md font-semibold text-gray-800">
                  {video.snippet.title}
                </h3>
                <p className="text-sm text-gray-600">
                  {video.snippet.description.slice(0, 100)}...
                </p>
              </a>
            </div>
          ))
        ) : (
          <p>No videos found.</p>
        )}
      </div>
    </div>
  );
};

export default EducationLibrary;
