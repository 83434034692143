import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const PrivacyPolicy = () => {
  return (
    <div>
    <Header />
    <div className="bg-gray-50 min-h-screen py-10">
      <div className="max-w-6xl mx-auto px-4">
        <div className="text-center mb-10">
          <h1 className="text-4xl font-bold text-gray-800 mb-4">
            Privacy Policy
          </h1>
          <p className="text-lg text-gray-600">
            Effective Date: November 19, 2024
          </p>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <p className="text-gray-700 leading-relaxed mb-6">
            At DIY Flex Realty, your privacy is important to us. This Privacy
            Policy explains how we collect, use, disclose, and safeguard your
            information when you visit our website, www.diyflexrealty.com (the
            “Site”). Please read this Privacy Policy carefully. By using the
            Site, you agree to the practices described in this policy.
          </p>

          <div className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              1. Information We Collect
            </h2>
            <ul className="list-disc list-inside text-gray-700 leading-relaxed">
              <li>
                <strong>Personal Information:</strong> Name, email address,
                phone number, mailing address, and any other information you
                provide when contacting us or filling out forms on the Site.
              </li>
              <li>
                <strong>Usage Data:</strong> Information about your interaction
                with the Site, such as IP address, browser type, pages visited,
                time spent on the Site, and other diagnostic data.
              </li>
              <li>
                <strong>Cookies and Tracking Technologies:</strong> Data
                collected through cookies, web beacons, and similar
                technologies to enhance your browsing experience.
              </li>
            </ul>
          </div>

          <div className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              2. How We Use Your Information
            </h2>
            <ul className="list-disc list-inside text-gray-700 leading-relaxed">
              <li>Provide and improve our services.</li>
              <li>Respond to your inquiries and communicate with you.</li>
              <li>Process transactions and send confirmations.</li>
              <li>Personalize your experience on the Site.</li>
              <li>Analyze website usage for optimization purposes.</li>
              <li>Comply with legal and regulatory obligations.</li>
            </ul>
          </div>

          <div className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              3. How We Share Your Information
            </h2>
            <ul className="list-disc list-inside text-gray-700 leading-relaxed">
              <li>
                With third-party service providers to perform services on our
                behalf, such as payment processing, data analysis, and
                marketing.
              </li>
              <li>
                To comply with legal obligations, enforce our terms and
                conditions, or protect our rights.
              </li>
              <li>
                With your consent or as otherwise disclosed at the time of
                collection.
              </li>
            </ul>
          </div>

          <div className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              4. Cookies and Tracking Technologies
            </h2>
            <p className="text-gray-700 leading-relaxed">
              We use cookies to enhance your experience on our Site. You can
              manage your cookie preferences through your browser settings.
              Disabling cookies may limit some functionalities of the Site.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              5. Third-Party Links
            </h2>
            <p className="text-gray-700 leading-relaxed">
              The Site may contain links to third-party websites. We are not
              responsible for the privacy practices of those websites. We
              encourage you to review their privacy policies.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              6. Data Security
            </h2>
            <p className="text-gray-700 leading-relaxed">
              We implement reasonable technical and organizational measures to
              protect your information. However, no system can guarantee
              absolute security.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              7. Your Rights
            </h2>
            <p className="text-gray-700 leading-relaxed mb-4">
              Depending on your jurisdiction, you may have the following rights:
            </p>
            <ul className="list-disc list-inside text-gray-700 leading-relaxed">
              <li>Access, correct, or delete your personal information.</li>
              <li>Object to or restrict processing of your data.</li>
              <li>Opt-out of receiving marketing communications.</li>
              <li>Data portability, where applicable.</li>
            </ul>
            <p className="text-gray-700 leading-relaxed">
              To exercise these rights, contact us at{" "}
              <strong>Support@DIYFlexRealty.com</strong>.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              10. Contact Us
            </h2>
            <p className="text-gray-700 leading-relaxed">
              If you have any questions or concerns about this Privacy Policy,
              please contact us at:
            </p>
            <p className="text-gray-700 leading-relaxed mt-2">
              <strong>DIY Flex Realty</strong>
              <br />
              Email: <a href="mailto:Support@DIYFlexRealty.com">Support@DIYFlexRealty.com</a>
              <br />
              Phone: 720-422-1156
            </p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    </div>
  );
};

export default PrivacyPolicy;
