import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Map.css';

const Map = () => {
  const navigate = useNavigate();

  const handleStateClick = (stateId) => {
    if (stateId === 'colorado') {
      navigate('/lead_form');
    }
  };

  return (
    <div className="usa-map-container">
      <div className="svg-container">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="usa" viewBox="0 0 612 378.2">
        <g>
            <path class="state" id="west-virginia" d="M473.6 170.8c-0.8 0.4-1.1 1.7-1.4 3 -0.3 1.2-0.5 2.4-1.2 3.1 -0.5 0.5-0.8 1.2-1 2 -0.4 1.1-0.9 2.4-2.2 2.5 0.1 1.3 0.5 4.5 2.2 5.7 1.4 1.1 2.4 2 3.5 2.9 0.6 0.6 1.3 1.2 2.1 1.9 0.6 0.5 1.1 1 1.6 1.6 1.4 1.5 2.2 2.3 3.7 1.7 0.9-0.4 1.6-0.7 2.2-1 1-0.5 1.8-0.9 3.5-1.2 1.3-0.2 2.8-1.1 4-1.8 1.1-0.7 2.1-1.3 3-1.5 0.8-0.1 1.1-2.2 1.3-3.7 0.2-1.2 0.4-2.4 0.7-3.1 0.3-0.5 0.7-2.2 1.2-3.9 1.3-4.7 2.1-7.5 3.2-8 0.3-0.1 0.5-0.1 0.8 0.1 0.3 0.2 0.7 0.5 1 0.7 0.7 0.5 1.5 1.2 2 1 0.2-0.1 0.4-0.3 0.6-0.6 0.4-0.9 0.5-2 0.5-2.9 0.1-1.2 0.1-2.4 1.2-2.5 1-0.2 5.5-6 5.7-7.4 0.2-1.3-1-3.1-1.4-3.2 -0.7 0.1-6.3 3-7.2 4.3 -1.6 2.3-2.4 2.6-2.9 2.5 -0.2 0-0.6-0.1-0.9-0.7 -0.6-1.2-0.7-5.1-0.7-5.6l0-1.7c-3.5 0.7-7.2 1.4-11.2 2.1l-0.5 0.1 -1.4-7.2c-1 6.1-4.2 11.2-5.9 12.5 -1.7 1.3-3.7 5.8-3.7 5.9 0 0 0 0 0 0C475.9 168.4 475.6 169.8 473.6 170.8z"/>
            <path class="state" id="virginia" d="M506.3 167.6c-0.2 0-0.3 0.9-0.3 1.6 -0.1 1-0.1 2.2-0.6 3.3 -0.3 0.7-0.7 1.1-1.3 1.2 -1 0.2-1.9-0.5-2.9-1.2 -0.3-0.2-0.6-0.4-0.9-0.6 -0.7 0.6-1.9 4.9-2.5 7.2 -0.5 2-1 3.5-1.3 4.2 -0.3 0.6-0.5 1.7-0.6 2.8 -0.3 2.1-0.6 4.3-2.1 4.6 -0.7 0.1-1.7 0.7-2.7 1.3 -1.3 0.8-2.8 1.7-4.4 2 -1.6 0.3-2.3 0.6-3.2 1.1 -0.6 0.3-1.3 0.6-2.3 1 -0.5 0.2-0.9 0.3-1.4 0.3 -1.5 0-2.4-1.1-3.5-2.2 -0.4-0.4-0.7-0.8-1.1-1.2 -1 1-4 4.2-6.2 7 -2 2.5-6.6 5.9-9.2 7.6 0.6-0.1 1-0.1 1.2-0.1 4-0.5 42.8-5.8 48.5-6.9 2.1-0.4 7.3-1.5 13.3-2.8 10.3-2.2 21.9-4.7 24.2-5 0.8-0.1 1.5 0 2.3 0.2 0-0.9 0-1.9-0.5-3 -1-2.4-4.1-1.6-4.7-2.6 -0.6-0.9-0.7-8.6-3.2-11.3 -1.1-1.2-1.6-2.3-1.9-3.3 -2.9-0.9-7.7-2.4-9.4-2.8 -0.5-0.1-1-0.2-1.4-0.2 -0.9-0.1-1.7-0.2-2.1-0.9 -0.3-0.6-0.1-1.5 0.7-3.1 1.2-2.3 2.3-4.2 1.9-5.1 0-0.1-0.2-0.4-1-0.7 -1.1-0.3-2.5-1.2-3.9-2.1 -1-0.7-2.9-1.9-3.3-1.7 -0.2 0.3-0.3 0.8-0.4 1.3 -0.2 0.9-0.4 1.8-1.1 2 -0.7 0.2-1.4-0.2-1.9-0.6 -1.5-1.2-3.6-2-4.8-2.5 0.5 0.8 0.8 1.9 0.7 2.8C512.7 160.9 507.9 167.5 506.3 167.6z"/>
            <path class="state" id="wisconsin" d="M351.5 67.3c-0.3 0.4-0.7 3.6-0.7 8.8 0 1.7-1.2 2.2-2.5 2.8 -0.8 0.3-1.7 0.7-2.6 1.4 -0.6 0.5-0.9 1-1 1.6 -0.1 1.1 0.7 2.4 1.4 3.2 0.6 0.6 0.7 2.7 0.8 7.4 0 1.7 0.1 3.3 0.2 3.9 0.2 1 0.9 1.3 2.4 1.9 0.3 0.1 0.7 0.2 1 0.4 1.4 0.5 4 2.8 6.5 4.9 1.6 1.4 3.1 2.6 4 3.2 1.8 1.2 2.6 4.5 3.1 6.7 0.2 0.7 0.3 1.4 0.4 1.6 0.7 0.7 2.1 10.1 2.1 10.2 0.1 0.7 2.5 1.9 4.7 2.7l0.2 0.1 0.1 0.1c0 0 0.1 0.2 0.3 0.5l30.1-3.6c-0.1-1.8-0.2-3.6-0.5-4.3 -0.6-1.6-1.1-9.8-0.9-13.1 0.2-3.3 1.2-5.8 1.5-7.3 0.3-1.5 4.3-12.6 4.3-14.6 0-0.5 0-1.1 0.1-1.7 -1 0-1.8 0.1-2.1 0.6 -0.6 0.9-2.1 2.9-3.9 3.1 -0.8 0.1-1.5-0.2-2.1-0.9 -1.2-1.2-1.8-3.3-2.4-5.1 -0.3-1-0.6-1.9-1-2.6 -0.9-1.7-5.9-3.7-9.3-4.1 -3.3-0.3-11.8-2.8-14.3-5.2 -0.6-0.6-1.2-1.4-1.9-2.5 -0.7 0.3-1.3 0.4-1.9 0.3 -2.1-0.4-3.7-0.4-2.7-2.4s0.7-3-0.4-3c-1.1 0-9.5 5.2-10.3 4.5 -0.2-0.2-0.1-0.6 0.2-1.2C352.8 66.5 351.7 67.1 351.5 67.3z"/>
            <path class="state" id="south-dakota" d="M228.6 91.2L228.6 91.2l-2.4 29.7c4.5 0.2 37.3 1.8 46.6 2 9.9 0.2 13.8 0 13.9 0l0.4 0 0.1 0.3c0.7 1.7 2.3 4.1 3.2 3.7 3.3-1.4 7.8-2.7 10.7-1 2 1.2 3.3 2 4 2.5 0.6-2.3 1.1-4.7 1.1-5 0-0.4-0.5-4.4-0.8-7l-0.1-0.6h1.9c0-3.7-0.1-24.9 0-25.8 -0.1-0.2-1-1-1.6-1.6 -1.3-1.1-2.5-2.2-2.7-3.2 -0.1-1.2 1.2-3.3 2-4.4 -4.9 0-26.6-0.3-36.6-0.5 -10.5-0.2-35.1-2.2-38.9-2.5L228.6 91.2z"/>
            <path class="state" id="kentucky" d="M434.8 179.3c0.1 0.7 0.2 2-0.1 3.7 -0.5 2.6-5.4 8-5.6 8.2l-0.2 0.2 -4.8-1.2c-0.8 0.8-3.5 3.2-5.5 3 -1-0.1-1.2 0.4-1.4 1.3 -0.1 0.3-0.2 0.7-0.4 0.9 -0.4 0.5-1 0.3-1.6 0 -0.7-0.3-1.9-0.8-3.8-0.8 -2 0-3.9 0.8-5 1.3l-0.9 0.7v1.3c-0.2 0.8-1.1 4.3-2.3 5 -0.7 0.4-1 1.4-0.9 3 0 0.3-0.1 0.5-0.3 0.7 -0.5 0.4-1.5 0.2-2.9-0.1 -1.4-0.3-3.4-0.8-4.1-0.2 -1 1-0.9 2-0.8 2.7 0 0.3 0.1 0.6 0.1 0.8 0 0.1-0.1 0.4-0.1 0.7 -0.6 2.6-0.7 4.3-0.6 5 0.1 0.1 0.3 0.3 0.4 0.4 4.6 0.3 11.4 0.3 12.4-0.2 0.1-0.3 0.1-0.7 0.1-1.1 0-0.9 0.1-2.2 1.2-2.2 1.1 0 38.2-3.6 50-4.8 1-0.6 8.1-5.4 10.5-8.5 2.2-2.8 5.2-5.9 6.3-7 -0.6-0.6-1.2-1.1-1.8-1.6 -1-0.9-2-1.8-3.4-2.9 -2.2-1.7-2.5-5.9-2.6-6.8 -0.8-0.5-1.5-1.4-2.3-2.4 -0.9-1.1-1.9-2.5-2.6-2.3 -0.6 0.1-1.1 0.5-1.7 1 -1 0.8-2.3 1.7-4.4 1.5 -2.5-0.2-4.6-1-5.4-1.3 -0.3 0.2-1 0.5-1.7 0.3 -0.8-0.2-1.4-0.8-1.8-1.9 -0.3-0.7-0.5-1.2-0.6-1.7 -0.5-1.6-0.6-1.8-2.2-1.4l-2.3 0.5c0.5 3 0.7 3.6 0.8 3.7 0.3 0.5 0.2 0.8 0 1C441.9 179.4 437.3 179.4 434.8 179.3z"/>
            <path class="state" id="illinois" d="M399.3 205.5c0.7 0.2 1.6 0.4 1.9 0.3 0-0.9-0.1-3 1.5-3.9 0.6-0.3 1.4-2.4 1.8-4.2l0-1.9 0-1.9c0-1.9 1.8-5 3.4-7.8 0.4-0.8 0.9-1.5 1.2-2.2 1.1-2-0.4-4.9-1.1-6.4 -0.3-0.7-0.5-1-0.5-1.3 0-0.4 0.1-0.9 0.4-1.9 0.4-1.3 0.8-3 0.7-4.1 -0.4-2.1-2.2-23.6-2.4-26.4 -0.2-2.3-0.1-4.4 0-5.9 -3.6-1.7-3.2-6.5-3.5-7.5 -0.2-0.6-0.3-2.4-0.4-4.4l-29.5 3.5c1 1.5 2.8 4 4.8 6.6 0.7 1 0.9 2 0.5 3 -1.4 3.4-8.8 5.9-9.9 6.1 -0.4 0.5 0.2 2.7 0.5 4.1 0.3 1.2 0.6 2.3 0.6 3 0 1.9-3.8 6.7-4.6 7.6 0 1-0.2 6.6 1.2 9.6 0.8 1.8 3 3.5 4.8 4.8 1.7 1.3 2.8 2.1 2.8 3 0 0.2 0 0.4 0 0.6 0 1.1-0.1 3.3 0.5 3.8 0.1 0.1 0.2 0.1 0.3 0.1 0.4-0.1 1.1-0.3 1.7-0.5 1.6-0.5 3.2-1 4.1-0.4 0.4 0.3 0.6 0.7 0.6 1.4 0 1.2-0.6 2.9-1.2 4.7 -0.6 1.7-1.4 4.1-1 4.6 0.3 0.4 2 1.6 3.7 2.8 4.6 3.1 7.8 5.5 7.8 7.1 0 0.4 0 0.9 0 1.3 -0.1 2-0.2 4.8 0.9 5.5 0.9 0.7 1.8 0.8 2.3 0.8 0 0 0-0.1 0-0.1 -0.1-0.9-0.4-2.3 1.1-3.6C395.3 204.5 397.4 205 399.3 205.5z"/>
            <path class="state" id="iowa" d="M308.2 116.8h-1.8c0.2 1.6 0.7 6 0.7 6.5 0 0.6-0.9 4.6-1.2 5.8 0.7 1.1 4.4 7.1 5.3 9.8 0.9 2.8 4.3 17.6 4.9 20.4l45.1-2.3 2.8 2.1c1.7-2.1 4.2-5.6 4.2-6.7 0-0.6-0.3-1.7-0.6-2.8 -0.6-2.4-1-4-0.4-4.9 0.2-0.3 0.4-0.4 0.7-0.5 1.3-0.2 8-2.6 9.1-5.4 0.3-0.7 0.2-1.4-0.4-2 -2.9-3.7-5.2-7.2-5.7-8 -1.4-0.5-5-1.9-5.2-3.4 -0.2-1.7-1.4-9-1.9-9.7 -0.2-0.2-0.3-0.5-0.4-1.1L308.2 116.8 308.2 116.8z"/>
            <path class="state" id="indiana" d="M435.5 133.7L414 136c-1.3 1.6-2.9 2.7-4.8 2.7 -0.8 0-1.6-0.1-2.2-0.3 -0.1 1.4-0.1 3.4 0 5.5 0.3 4.2 2.1 24.4 2.4 26.3 0.2 1.4-0.3 3.2-0.7 4.5 -0.2 0.6-0.4 1.3-0.3 1.5 0 0.1 0.2 0.5 0.4 0.9 0.9 1.7 2.5 4.8 1.1 7.4 -0.4 0.7-0.8 1.4-1.3 2.2 -1.5 2.5-3.3 5.7-3.3 7.3v1.3c1.1-0.6 3.4-1.7 5.9-1.7 2.1 0 3.4 0.6 4.2 0.9 0.2 0.1 0.4 0.2 0.5 0.2 0-0.1 0.1-0.3 0.1-0.4 0.2-0.8 0.6-2.2 2.5-2 1.5 0.2 4-2 4.8-2.9l0.2-0.2 4.8 1.2c1.5-1.7 4.8-5.7 5.1-7.5 0.4-2.3 0-3.9 0-3.9l-0.2-0.7 0.7 0c3.7 0.2 7.2-0.1 7.6-0.6 -0.1-0.1-0.4-0.5-1-4.5C439 162.8 436 137.6 435.5 133.7z"/>
            <path class="state" id="nebraska" d="M319 167.7l-3.6-7.3 -0.1 0 -0.1-0.4c0-0.2-3.9-17.8-4.9-20.7 -0.9-2.7-4.8-9-5.3-9.7 -0.3-0.2-1.7-1.2-4.5-2.8 -2-1.2-5.5-0.8-9.8 1 -2 0.8-3.8-2.6-4.4-3.9 -1.2 0-5.3 0.2-13.6 0 -9.3-0.2-42.2-1.8-46.6-2l-2.3 27.9 22.4 1.8 -0.8 15.1L319 167.7z"/>
            <path class="state" id="kansas" d="M283.8 212.8h45.1l-0.8-30.9c-2.6-2.2-6.5-6.1-5.4-7.6 1-1.3 2-3.4 2-4 -1-0.3-4.5-1.4-5.1-1.6l-74.3-1 -1.8 43.8C258.1 212 280.8 212.8 283.8 212.8z"/>
            <path class="state" id="missouri" d="M381.4 224.7c0.1 0.4 2.4 0.2 3.3 0.1 0.9-0.1 1.7-0.2 2.3-0.1 0.6-1.1 2.5-5 2.7-8 0.1-1.1 0.4-1.8 1.1-2.1 0.5-0.2 1 0 1.5 0.3 0-1.1 0.3-2.7 0.6-4.5 0 0 0 0 0-0.1 -0.7 0-1.7-0.2-2.8-1 -1.5-1.1-1.5-4.1-1.4-6.4 0-0.5 0-0.9 0-1.3 0-1.2-4.8-4.5-7.4-6.3 -2.3-1.6-3.6-2.5-4-3 -0.7-1 0-3.1 0.9-5.5 0.5-1.6 1.1-3.3 1.1-4.3 0-0.4-0.1-0.5-0.2-0.5 -0.5-0.4-2.1 0.2-3.2 0.5 -0.7 0.2-1.4 0.5-1.9 0.5 -0.4 0.1-0.8-0.1-1.2-0.3 -0.9-0.8-0.9-2.7-0.9-4.6 0-0.2 0-0.4 0-0.6 0-0.4-1.4-1.4-2.4-2.1 -1.9-1.4-4.2-3.1-5.1-5.2 -1.5-3.1-1.3-8.7-1.3-9.9l-2.7-2 -44.4 2.3 3.7 7.4c0.9 0.3 4.2 1.3 5 1.6 0.3 0.1 0.5 0.3 0.6 0.6 0.4 1.3-1.6 4.3-2.1 5 -0.3 0.5 2 3.3 5.4 6.3l0.2 0.1 0.8 31.9 0.2 6.2c12.8-0.7 51.7-2.8 53.5-0.9 1.4 1.4 0 3-1 4.2C382 223.4 381.4 224.2 381.4 224.7z"/>
            <path class="state" id="arkansas" d="M379.8 242.5c0.1-0.4 0.6-1 1.4-2.2 1.1-1.5 2.7-3.7 2.7-4.8 0-1 1-2.9 2.1-5 0.8-1.6 2.2-4.2 2-4.7 -0.3-0.3-2.1-0.1-3 0 -2.2 0.2-4.2 0.4-4.4-1 -0.1-0.9 0.6-1.8 1.4-2.8 1.2-1.4 1.7-2.1 1-2.8 -1.8-1.2-33 0.1-52.6 1.2 0.5 2.6 2.2 12.8 2.4 15.1 0.2 2.3 0.2 23.1 0.3 27 0.3 0.1 0.4 0.2 0.5 0.3l4.9 1 0 0.4c0 2.3-0.1 5-0.1 7.7l35.4-1.8c-0.3-1.4-0.5-2.7-0.6-3.8 -0.4-4.2-0.3-9.9 2.1-13.3C377.4 250.1 379.5 243.4 379.8 242.5z"/>
            <path class="state" id="delaware" d="M544.5 143.4c-3.2 1.5-3.6 2.3-3.6 2.4 0 0.1-0.1 0.2-1 0.6l5 17.3 7.8-1.8c-0.1-0.3-0.2-0.6-0.3-1 -0.9-2.2-5.4-6.4-5.6-7.2C546.6 152.8 544.5 143.4 544.5 143.4z"/>
            <path class="state" id="north-dakota" d="M268.4 79.3c10.6 0.2 34.1 0.4 37.2 0.5 0.1-0.3 0.2-0.8 0.2-1.1 0-0.3-0.6-2.1-1-3.5 -0.7-2.2-1.4-4.5-1.5-5.4 0-0.3-0.1-0.9-0.2-1.7 -0.3-4-1.3-14.5-2.3-17.7 -0.8-2.5-1.9-10.4-2.7-15.8 -16.3 0-40.1-0.4-65.9-2l-2.8 44.4C233.3 77.1 257.9 79.1 268.4 79.3z"/>
            <path class="state" id="new-hampshire" d="M566.4 62c0.9 1.6-0.7 5.7-2.1 8.9 -0.5 1-0.3 3.3-0.1 5.4 0.1 1.1 0.2 2.1 0.2 3.1 0 1.3-0.1 3.2-0.2 5.1 -0.1 2.1-0.2 4.4-0.2 5.1 0.1 1.1 0 2.8 0 3.7 1.9-0.5 4-0.9 6-1.4 6.7-1.5 7.4-1.7 10.2-4.1 0.6-0.5 1.2-0.9 1.7-1.1 0-0.1 0-0.3 0.1-0.4 0.1-0.4 0.3-0.8 0.5-1.4 -3.2-0.6-5.3-3.9-5.7-6 -0.3-1.4-2.7-9-4.6-15.1 -0.6-1.8-1.1-3.4-1.5-4.8 -0.7-2.1-1.1-4.1-1.5-5.8 -0.9 0.6-1.8 1-2 1.1 -0.3 0.1-0.6 2.3-1.3 3.3C565.9 59.7 566.1 61.4 566.4 62z"/>
            <path class="state" id="vermont" d="M565.5 62.5c-0.4-0.6-0.6-2.4-0.7-4.3 -2.3 0.8-10 3.1-17.1 5.1 0.6 1.3 1.3 2.8 1.7 4.2 0.5 1.7 0.8 4.5 1 7.2 0.3 2.7 0.6 5.7 1.1 6.5 0.3 0.4 0.6 0.8 0.9 1.2 0.9 1.3 2 2.7 2.7 5.1 0.4 1.2 0.7 4.3 1 7.7 1.1-0.3 3.7-0.9 6.8-1.7 0-0.8 0.1-2.7 0-3.8 -0.1-0.8 0-3 0.2-5.3 0.1-1.9 0.2-3.8 0.2-5 0-0.9-0.1-1.9-0.2-3 -0.2-2.3-0.4-4.6 0.2-5.9C565.2 66.4 566.1 63.4 565.5 62.5z"/>
            <path class="state" id="mississippi" d="M407.5 238.5c0 0-25.3 2-25.5 2.2 -0.5 0.7-1.3 1.8-1.3 2 -0.3 1.1-2.5 7.8-4.7 10.9 -2.3 3.2-2.3 8.6-2 12.6 0.4 4.1 2 10.5 2.7 13.2l0.2 0.9c0.4 1.6-0.8 3.5-2.4 6 -0.4 0.6-0.8 1.2-1.2 1.9 -1.5 2.6-2.9 9.6-3.3 11.8l23.3-1.4 0 0.6c0 0-0.1 3.6-0.1 5.4 0 1.4 1.9 3.7 3.2 5.4l0.7 0.8c0.3 0.4 0.7 0.7 1.2 0.7 0.1 0 0.2 0 0.3 0 -0.3-0.8-0.1-1.7a1.7-2.4 2.7-1.1 6-1.7 7.7-1.7 0.6 0 1.5 0 2.4 0 0 0 0 0 0 0 0-2.7-2.4-19.9-2.5-20L407.5 238.5z"/>
            <path class="state" id="georgia" d="M498.5 290.8c-0.3-1.6-0.4-3.2-0.2-4.7 0.8-5.9 1.7-10 4.1-14.6 0.1-0.2 0.2-0.4 0.3-0.6 -0.7 0-1.5 0-2 0 -2.4-0.1-3.1-3.1-3.4-4.6 0-0.2-0.1-0.3-0.1-0.4 -0.1-0.4-1-1.1-1.6-1.6 -1.1-0.8-1.6-1.3-1.6-1.8 -0.1-0.6-2.5-5.1-4.8-6.2 -1.3-0.6-3.4-2.8-5.6-5.1 -2.1-2.2-4.2-4.4-5.6-5.2 -1.8-1.1-3.3-2.9-4.6-4.4 -0.8-1-1.6-1.9-2.1-2.1 -1.3-0.5-4.3-1.7-5.2-4.7 -0.1-0.5-0.1-1 0.2-1.5l-27.1 2.4c0.5 1.6 2.2 7.1 4.4 14.7 2.6 9.4 7.9 21.9 8.8 23.5 0.6 1.2 0.1 2.9-0.5 4.7 -0.4 1.1-0.8 2.3-0.6 2.9 0.2 1.1 0.9 4.3 0.9 7.1 0 1.8 2.6 6.2 3.8 8 3.4-0.2 31.4-1.5 32.9-1.5 1 0.1 1.5 0.6 1.9 1 0.4 0.4 0.5 0.5 0.7 0.4 0.1-0.3-0.2-1.9-0.3-2.9 -0.3-1.8-0.4-2.8-0.1-3.3C491.2 290.1 491.7 289.5 498.5 290.8z"/>
            <path class="state" id="idaho" d="M97.7 68.7c-2.6 3.4-8 10.4-7.7 11.6 0.2 0.4 0.4 0.8 0.6 1.1 0.8 1.4 1.5 2.8 1.5 4.3 0 1.8-6.6 26.5-7.3 29.3l0 0.7c29.5 6.4 58.8 10.4 62.9 10.9l5.6-36.9c-2.9-0.1-15.4-0.7-19.2-1.6 -3-0.7-4.7-7.7-6.2-14.8 -0.4-1.7-0.7-3.4-0.9-3.7 -0.3-0.3-1.9 0.1-2.8 0.4 -0.8 0.2-1.6 0.5-2.2 0.5 -0.4 0-0.8 0-1.1-0.4 -0.4-0.5-0.3-1.1-0.1-2.8 0.1-0.7 0.2-1.7 0.3-2.8 0.4-4.2 3.3-9.8 4.2-11.4 -1.2-0.6-4.3-2.1-5.7-3.9 -0.7-0.9-1.6-2.3-2.5-3.8 -1.1-1.8-2.3-3.7-3.1-4.5 -1.1-1.1-1.4-3.9-1.7-6.8 -0.2-1.6-0.3-3-0.6-4.1 -0.5-1.9 0.2-9.2 0.8-14.2 -2.4-0.5-4.8-1-7-1.4 -0.9 5.8-3 16.3-4.6 22.2 -2 7.1-3.1 19.2-3.3 21 1 1.1 4.3 4.7 3.3 6.6C100.6 64.9 99.4 66.5 97.7 68.7z"/>
            <path class="state" id="california" d="M85.9 245.9c0.9-1.4 1.9-2.9 1.9-3.9 0-1.3 1.6-3.1 3-4.7 0.6-0.7 1.2-1.3 1.5-1.8 0.4-0.7-0.6-3.7-1.3-5.8 -0.4-1.3-0.9-2.5-1-3.5 -0.2-0.9-0.1-1.8 0.1-2.6l-49.3-71.1 0.1-0.2 10-43.6c-18.2-4.5-35.3-9.1-43.9-11.4 0.2 2.6 0.3 6.6-1.3 10.9 -2.1 5.6-5.9 8.3-5.9 11.3 0 3.1 3.2 6.2 3 10S0 141.1 0.7 143s4.8 8.4 4.6 9.5c-0.2 1.1-1 3.5 0.7 5.5 1.7 2 3.1 3.3 3 6.4s-1 6 0.2 8.4c1.1 2.5 5.5 3.1 4.6 5.6 -0.9 2.6-3.3 1.8-3 5.6 0.3 3.8 3 7.9 4.3 10.8 1.3 2.9 4.7 8.2 4.9 11.9s-1.8 6.4-0.5 8.4c1.4 2 10.1 3.5 12.1 5.6 2 2.1 1.9 4.2 3.9 5.4 1.9 1.2 5.9 1.2 6.5 2.1 0.6 0.9-1.3 3.4 0.6 4.6 1.9 1.2 3.7 1.3 7.1 5.7 3.4 4.4 3 12.9 3.5 14.5 0.6 1.6 24.2 3.3 26.1 4.3 0.2 0.1 0.8 0.5 1.8 1.1 2.7-1.3 4.6-2.6 4.7-3.1 0-0.5-0.3-1.4-0.6-2.3 -0.6-1.9-1.2-4-0.6-5.2C84.9 247.3 85.4 246.6 85.9 245.9z"/>
            <path class="state" id="nevada" d="M79.9 115.8c-8.8-2-18.5-4.3-27.9-6.6l-10 43.3 48.5 70c0.1-0.3 0.2-0.6 0.3-0.8 0.3-0.7 0.5-1.3 0.5-1.7 0-0.2 0-0.6-0.1-1.1 -0.3-5-0.4-10.4 1.1-12 0.3-0.3 0.7-0.5 1.2-0.5 1.7 0 2.8 1.2 3.6 2 0.8 0.9 1 1 1.3 0.8 0.9-0.6 2.6-7.1 3.9-13.2h0l13.3-73.1C104.6 120.9 92.1 118.5 79.9 115.8z"/>
            <path class="state" id="wyoming" d="M155.6 83.9l-1.3 5.5 -5.7 37.9 -1.9 14.5c2.8 0.4 16.8 2.4 38.5 4.9 21.7 2.5 34.9 2.9 37.5 3l4.8-58.6c-3.2-0.2-20.2-1-33.8-2.2C180 87.7 159 84.5 155.6 83.9z"/>
            <path class="state" id="oklahoma" d="M266 219.5v29c1.3 0.9 4.4 2.6 6.2 2.5 1.8-0.2 2.3 1.6 2.6 2.8 0.3 1 0.5 1.7 0.9 1.8 0.6 0.1 2.4 0 4.3-0.1 4.6-0.3 7.9-0.4 8.9 0.2 1.1 0.6 1.6 2 1.9 2.9 0.1 0.4 0.3 0.8 0.4 0.9 0.1 0 1 0 1.7-0.1 1.5-0.1 3.9-0.3 6.1 0 1.4 0.2 1.9-0.1 2.4-0.3 1-0.5 1.9-0.7 4.9 0.4 5.1 2 7 1.6 11.3 0 4.3-1.6 6.2-1.8 8.3-0.7 1.2 0.6 4.1 2.2 6 3.2 0-4.9-0.1-24.2-0.3-26.4 -0.2-2.4-2.5-15.4-2.5-15.5l-0.1-0.6 0.1 0 -0.1-5.8h-45.2c-4.2 0-46.8-1.4-51.9-1.6l-0.5 6L266 219.5z"/>
            <path class="state" id="texas" d="M343.2 296.8c-1.1-1.4-2.1-2.7-2.2-3.8 -0.2-1.7-2.3-4.8-3.1-5.8 -0.8-0.9-0.6-14.3-0.5-22.6l-5.5-1.1v-0.4c-1.8-1-5.2-2.8-6.5-3.4 -1.6-0.8-3-0.9-7.5 0.8 -4.4 1.7-6.6 2.1-12 0 -2.8-1.1-3.4-0.8-4.1-0.5 -0.6 0.3-1.3 0.6-3 0.4 -2.2-0.3-4.4-0.1-5.9 0 -1.2 0.1-1.8 0.1-2.1 0 -0.5-0.2-0.7-0.8-1-1.6 -0.3-0.9-0.6-1.9-1.4-2.4 -0.9-0.5-5.4-0.2-8.4-0.1 -2.1 0.1-3.7 0.2-4.4 0.1 -1.2-0.1-1.5-1.4-1.8-2.5 -0.4-1.5-0.7-2.1-1.5-2.1 -2.7 0.3-6.9-2.7-7.1-2.8l-0.2-0.2v-28.5l-32.8-1.3h-0.8l-5.3 66.1 -44-2.8 0.3 3.2c2.3 2.1 7.9 9.2 10.1 11.3 2.5 2.4 7.9 6.5 8.7 7.6 0.8 1.1 1.7 10 2.8 11.8 1.1 1.8 15.2 15.1 17.9 13.5 2.7-1.6 6.6-10.6 8.3-10.8 1.7-0.2 10.9-2.9 17.8 3.9 6.8 6.8 8.8 14.4 10.2 17.2 1.4 2.7 5.4 8.3 6.7 9.6 1.3 1.3 3 2 3.6 2.8s-0.1 6.4 1.9 9.6c2.1 3.1 1.9 7 3.2 7.8s6.4 2.3 8.3 3.1c1.9 0.8 10 2.5 10.8 3s7.4 5.3 6.9 1.3 -4.3-11-2.7-18.4 6.7-14.8 9.1-16.4c2.5-1.5 9-2.9 12.3-4.9 3.3-2.1 9.8-9.5 12.3-11.1 2.2-1.4 6.8-3.8 10.5-5 0.1-1.3 0.3-2.5 0.6-3.2 0.5-0.9 0.8-3.4 1.1-5.9 0.2-1.4 0.3-2.8 0.5-4 0.3-1.5 0.6-3.1 1-4.4 0.4-1.8 0.8-3.3 0.8-4.2C345 299.2 344 297.9 343.2 296.8z"/>
            <path class="state" id="louisiana" d="M404.6 324.3c-4.1-1.8-8.1-3.6-7-5 1.1-1.4 2.8-5 1.9-6.3 -0.1-0.2-0.2-0.3-0.4-0.5 -0.3 0.1-0.6 0.1-0.9 0.1 -0.7-0.1-1.4-0.4-1.9-1.1l-0.7-0.8c-1.6-2-3.5-4.3-3.5-6.1 0-1.4 0-3.8 0.1-4.8l-23.4 1.4 0.1-0.6c0.1-0.4 1.7-9.5 3.6-12.7 0.4-0.7 0.8-1.3 1.2-1.9 1.4-2.2 2.6-4.1 2.3-5.2l-0.2-0.9c-0.5-1.8-1.4-5.2-2-8.5l-35.6 1.8c0 6.5 0 12.9 0.4 13.6 0.6 0.6 3.1 4.2 3.3 6.3 0.1 0.8 1.1 2.1 2 3.3 1.1 1.4 2.1 2.6 2.1 3.6 0 1-0.4 2.6-0.8 4.4 -0.3 1.4-0.7 2.9-1 4.4 -0.2 1.2-0.4 2.6-0.5 3.9 -0.3 2.7-0.6 5.3-1.2 6.3 -0.3 0.4-0.4 1.3-0.5 2.3 0.1 0 0.2-0.1 0.3-0.1 3.9-0.9 6.3-1 9.6-0.3 3.4 0.7 7.3 2.2 9.9 2.1s2.2-2.7 4.4-3.4 5.3 1.4 6.8 3.1c1.5 1.7 4.6 5.2 7.4 5.5s3.3-4.2 6.8-2.3c3.5 1.9 5 1.8 5-0.8 0-2.6 5.8 0.2 8.3 2.2C403.2 329 408.7 326.2 404.6 324.3z"/>
            <path class="state" id="florida" d="M516.3 326.6c-0.1-1.8 0.5-4.3-1.5-7.2 -2-2.9-9-10.3-11.3-15.5 -1.6-3.5-3.8-7.8-4.8-12 -3.3-0.6-6.2-1-6.9-0.9 -0.1 0.4 0.1 1.7 0.3 2.5 0.3 2.1 0.6 3.8-0.5 4.1 -0.8 0.2-1.3-0.3-1.7-0.7 -0.4-0.4-0.7-0.7-1.3-0.7 -1.2 0-22.1 0.9-33 1.5l-0.3 0 -0.2-0.2c-0.1-0.1-1-1.6-2-3.3 -3.1 0.2-27.9 1.9-31.5 2.7 -0.5 0.1-1.1 0.3-1.2 0.7 -0.3 1 1.6 3 2.8 4.2 0.3 0.3 0.5 0.5 0.7 0.8 0.8 0.9 0.7 2.8 0.2 4.8 3.3-1.4 9-3.7 13.3-2.8 5.2 1.1 13.2 9 14.5 9 1.3 0 5.6-1.6 7.7-3 2-1.4 4.8-5.2 7-5.3 2.3-0.1 12.7 10.9 14.8 11.4 2.1 0.5 4.5 0.2 5.7 2.3 1.2 2.1-0.6 16.6 0.1 17.7 0.7 1.1 6.6 9 11.8 14.8 5.2 5.8 5.9 9.6 7.3 9.9 1.4 0.3 4.2 0.7 5.8 2.6 1.7 1.9 3 6.2 3 6.9 0.1 0.7 2.9 1.5 6.1 0 3.2-1.5 5.1-4 5.3-6.4 0.2-2.5 1.1-17.4 0.8-18.2C527.1 345.1 516.4 328.4 516.3 326.6z"/>
            <path class="state" id="alabama" d="M423 307.7c0.6-2.1 0.8-4 0.3-4.6 -0.2-0.2-0.5-0.5-0.7-0.8 -1.6-1.6-3.5-3.6-3-5.2 0.2-0.7 0.9-1.2 1.9-1.4 3.6-0.8 26.5-2.3 31.2-2.6 -0.8-1.6-1.6-3.4-1.6-4.5 0-2.7-0.6-5.9-0.8-6.9 -0.2-0.9 0.2-2.1 0.7-3.4 0.5-1.4 1-3.1 0.6-3.9 -0.9-1.7-6.2-14.3-8.9-23.7 -2.2-7.8-3.9-13.3-4.4-14.8l-29.7 2.6 0.4 48.9c0.1 0.6 2.5 17.3 2.5 20.1 1.4 0 2.7 0 3.2 0.1 0.8 0.2 5.3 1.3 7.8 0.3C422.6 307.9 422.8 307.8 423 307.7z"/>
            <path class="state" id="oregon" d="M6.9 96.4c12.9 3.5 46.8 12.5 73.2 18.4 1.2 0.3 2.5 0.5 3.7 0.8v-0.7c2.9-11.1 7.3-27.9 7.3-29.2 0-1.3-0.7-2.5-1.4-3.8 -0.2-0.4-0.4-0.8-0.6-1.2 -0.7-1.3 2-5.1 7.8-12.6 1.5-2 2.9-3.7 3.1-4.2 0.5-1-1.5-3.8-3.2-5.5 -1.4-0.3-12.5-2.6-18.2-4 -5.9-1.4-24-0.5-24.2-0.5l-0.2 0L54 53.7c0 0-1.1-1.2-3.3-2.1 -0.9-0.3-2.4-0.2-4.1-0.1 -2.5 0.2-5.4 0.4-7.5-0.8 -2.2-1.3-2.1-3.1-2-4.9 0.1-1.2 0.1-2.5-0.5-3.9 -1.1-2.3-6.5-4.3-9.7-5.2 -0.9 4.3-3.2 14.8-5.8 21.1C17.8 66 9.1 80.9 8.6 82.8S6.5 93.5 6.8 95.5C6.8 95.7 6.9 96 6.9 96.4z"/>
            <path class="state" id="arizona" d="M160.3 205.1l-57.2-8.6c-0.6 2.9-2.7 12.3-4.2 13.4 -1.1 0.7-2-0.2-2.6-1 -0.7-0.8-1.5-1.7-2.8-1.7 -0.1 0-0.2 0-0.4 0.2 -1.4 1.5-1 8.8-0.8 11.3 0 0.6 0.1 1 0.1 1.2 0 0.6-0.2 1.3-0.5 2.1 -0.5 1.3-1 2.7-0.7 4.2 0.2 0.8 0.6 2 1 3.3 1.1 3.3 1.8 5.6 1.2 6.6 -0.3 0.5-0.9 1.1-1.6 1.9 -1.2 1.3-2.8 3-2.8 4 0 1.3-1.1 2.9-2.1 4.5 -0.5 0.7-0.9 1.4-1.2 1.9 -0.4 0.9 0.2 2.9 0.6 4.5 0.3 1.1 0.6 2 0.6 2.6 0 1.1-2.4 2.6-4.7 3.7 9.8 5.9 40.7 25.2 42.7 25.7 1.4 0.4 15 2 25.2 3.1L160.3 205.1z"/>
            <path class="state" id="new-mexico" d="M180.9 281.4l44.1 2.8 5.8-72.1 -69.6-6.8 -10.3 82.9c5.9 0.7 10.4 1.2 10.4 1.2v-6c0 0 17 1.1 19.8 1.7L180.9 281.4z"/>
            <path class="state" id="new-jersey" d="M553.6 130.6l2-7.3 -10.4-2.8c0.1 0.6-0.1 1-0.1 1l-1.1 9 4.8 6.7 -0.1 0.2c-0.1 0.5-1.3 4.2-3.2 5.5l2.1 9.5c0.8-0.7 2.2-1 3-0.6 1.4 0.8 0.8 3.3 1.5 3.6 0.7 0.3 4.5-5.1 5.4-10.7 0.9-5.6 0.7-12 0.9-12.8 0.1-0.2 0.6-0.6 1.3-1.1L553.6 130.6z"/>
            <path class="state" id="massachusetts" d="M589.5 96.1c-1.2 1.1 2.7 4-0.4 4.6 -1.5 0.3-2.9-3.8-4-4.4s-3.6-1.2-2.1-3c1.5-1.8 2.7-2.9 1.9-3.5 -0.6-0.4-2.2-1.1-2.9-2.1 -0.4 0.2-0.9 0.5-1.3 0.9 -3 2.6-4 2.9-10.6 4.3 -5.7 1.3-12.3 2.9-14 3.3 0.3 3.6 0.5 7.4 0.6 9.6 5.8-1.4 21.6-5 23.2-4.1 1 0.6 2.3 4.3 3.2 7.6 2.1-1.9 4.7-4.6 5.5-4.8 2-0.5 4.2-2.4 4.5-4.2C593.8 97.3 590.4 95.2 589.5 96.1z"/>
            <path class="state" id="maine" d="M612.3 48.7c-0.4-2-3.9-4.8-5.3-5.6 -1.5-0.9-0.2-2.7-1.7-4.2s-4.4-0.7-4.9-1.8c-0.5-1.1-2.5-9.5-4.2-13.6 -1.7-4.1-4.6-7.1-6.7-7.1 -2.1 0-4.4 4-6 3.5 -1.5-0.5-1.8-1.7-2.7-2.5s-2 0-2.9 2.4c-0.9 2.4-3 7.1-3.4 10.4 -0.4 3.3 0.1 10.5-0.3 12.9 -0.5 2.4-2.4 7.4-3.4 8.5 -0.2 0.3-0.6 0.5-0.9 0.8 0.4 1.8 0.9 3.9 1.6 6.1 0.4 1.4 1 3 1.5 4.8 2.1 6.5 4.4 13.8 4.7 15.2 0.3 1.8 2.2 4.7 5 5.2 0 0 0.1 0 0.1 0 1-2.2 2.2-5.1 2.1-6.4 -0.2-1.8-0.4-4.7 0.5-5.1 0.9-0.4 8.1-3.7 9-6.1 0.9-2.4 0-6.7 1.5-6.5 1.4 0.3 5.4 1.4 7.1-0.9s3.2-4.1 5.3-6C610.5 51.1 612.7 50.7 612.3 48.7z"/>
            <path class="state" id="rhode-island" d="M579.4 102.5c-0.4-0.2-2 0-4.4 0.4l2.1 9.3c1-0.4 2.2-0.7 3.4-1.1 0.4-0.1 1-0.6 1.7-1.2C581.2 106.1 580.1 102.8 579.4 102.5z"/>
            <path class="state" id="new-york" d="M573.7 117c-2.7 0.6-6.6 3.6-9 4.7 -1.6 0.8-2.2 0.6-2.2 0.1l-2.8 1 -0.2-0.1c-0.5-0.3-2.2-1.3-2.1-2.3l0.1-0.5c0.1-0.9 0.3-2.1 0.6-3.1 0.2-0.5-0.7-3.3-1.2-4.9 -0.8-2.3-1.2-3.8-1.1-4.5 0.2-1.2-0.7-16.4-1.6-19.5 -0.7-2.2-1.7-3.6-2.6-4.8 -0.3-0.4-0.6-0.9-0.9-1.3 -0.6-1-0.9-3.7-1.2-6.9 -0.3-2.6-0.5-5.3-1-7 -0.4-1.4-1.1-2.9-1.7-4.2 -5.9 1.7-11.2 3.2-12.2 3.4 -2.5 0.7-7 4.3-9.1 8.4 -2.1 4.1-6.7 7-6 8.1 0.7 1.1 5 4.3 4 6.3 -1 2.1-4.6 4.5-5.5 5.7 -0.9 1.2-6.5 2.4-8.3 2.4s-13.2 0.6-14.3 2.8c-1.1 2.2 1.1 4.9 1.2 6.7 0.1 1.6-4.1 6.8-6.7 9.4l0.7 3.9c5.2-1 43.7-8.8 46.1-9.1 1.9-0.2 2.7 0.8 3.8 2.2 0.6 0.7 1.3 1.6 2.3 2.5 1.2 1.1 1.9 2.2 2.2 3l12 3.2 -1.9 7.1 6.5 0.3c1.8-1 4.2-2.3 5.9-3.4 4.4-2.9 9.3-6.6 9.3-8.6C576.6 117.5 575.7 116.6 573.7 117z"/>
            <path class="state" id="connecticut" d="M574 103.1c-4.5 0.8-11 2.2-17.2 3.7 0 0.3 0 0.5 0 0.6 -0.1 0.5 0.6 2.5 1.1 4.1 1 3 1.5 4.8 1.2 5.6 -0.4 1-0.5 2.1-0.6 2.9l-0.1 0.5c0 0.2 0.7 0.8 1.4 1.2l3.3-1.1c0.5-0.8 1.4-1.8 2.2-2.5 1.8-1.6 4.6-3.3 10.9-5.5L574 103.1z"/>
            <path class="state" id="washington" d="M27.1 35.7c2.8 0.8 9.1 2.9 10.4 5.7 0.7 1.6 0.7 3 0.6 4.4 -0.1 1.7-0.2 3 1.5 4 1.8 1.1 4.5 0.9 6.9 0.7 1.9-0.1 3.5-0.3 4.6 0.2 2 0.8 3.1 1.8 3.5 2.2 2.2-0.1 18.5-0.8 24.3 0.5 5.3 1.3 15.3 3.4 17.7 3.9 0.3-2.7 1.4-14 3.3-20.8 1.7-6.2 3.7-16.6 4.6-22.1C80.1 9.2 65.8 5.4 65.8 5.4S54.4 0.8 52 0.6C49.6 0.4 50 1.1 50 1.1s1.2 3.5 1.3 4.4c0.1 1-2.2 4.6-2.7 5 -0.5 0.5-2.1 2.3-2.5 2.3 -0.3 0-5.6-1.3-9-3.5 -3.3-2.2-7.4-5.2-7.6-5.2 -0.3 0-1.9 4.2-1.5 5.5 0.4 1.3 1.5 8 1.5 9.8 0 1.8-2.2 15.2-2.2 15.2S27.2 35.1 27.1 35.7z"/>
            <path class="state" id="utah" d="M167.5 145.6c-13.5-1.7-21.3-2.8-21.4-2.8l-0.5-0.1 2-15c-2.6-0.4-15-2.1-31-4.8l-13.2 72.6 57.1 8.6L167.5 145.6z"/>
            <path class="state" id="pennsylvania" d="M542.9 130.8l1.2-9.5c0-0.1 0.5-1.7-2.1-4.1 -1.1-1-1.8-1.9-2.4-2.6 -1-1.3-1.6-2-2.9-1.8 -2.5 0.2-46 9.1-46.5 9.1l-0.5 0.1 -0.8-4.3c0 0-0.1 0.1-0.1 0.1 -1.1 0.9-4.1 2.8-7 4.6 1.2 4.9 3.9 17.3 4.1 23.7l1.9 10c20.1-3.8 49.8-9.7 52.3-10.7 0.2-0.5 0.9-1.5 4.6-3.1 1.4-0.6 2.6-3.6 3.1-4.9L542.9 130.8z"/>
            <path class="state" id="maryland" d="M538.9 146.7c-3.5 1-13 3.1-39.3 8.1l0 1.8c0 1.6 0.3 4.4 0.6 5.1 0.1 0.1 0.6 0.1 2.1-2 1.1-1.5 7-4.8 8.1-4.8 0.3 0 0.6 0.2 1 0.5l0.1-0.3c0.1 0 3.7 1.1 6.1 3 0.7 0.5 0.9 0.5 0.9 0.4 0.2-0.1 0.4-0.8 0.5-1.3 0.1-0.6 0.3-1.2 0.5-1.6 0.1-0.3 0.4-0.4 0.7-0.5 0.9-0.2 2.1 0.6 4.1 1.8 1.3 0.8 2.6 1.7 3.6 2 0.9 0.2 1.4 0.6 1.7 1.2 0.6 1.3-0.4 3.2-1.9 6 -0.8 1.6-0.8 2-0.7 2.1 0.1 0.2 0.7 0.3 1.3 0.3 0.4 0.1 1 0.1 1.5 0.2 1.6 0.4 6.1 1.7 9.1 2.7 0.1-0.9 0.5-1.5 1.2-1.9 1.7-0.9 5.3 0.8 5.3 2.1 0 1.3 0 11.2 0.6 12 0.5 0.6 3.3 0.9 3.5 0 0.3-1.4-1.2-4.1-1.1-4.9 0.2-0.9 1.4-4.1 1.7-5.2 0.3-1.1 2.5-3.6 2.7-6.4 0.1-1.9 0.4-3.1 0.2-4.4l-8.6 2L538.9 146.7z"/>
            <path class="state" id="colorado" onClick={() => handleStateClick('colorado')} d="M242.6 211.5l1.8-43.8h0l0-0.5v0l0.7-14.6 -21.9-1.8c-0.1 0-13.9-0.2-38.1-3 -6.2-0.7-11.7-1.4-16.6-2l-7.1 58.5 70 6.9C231.7 211.1 236.2 211.3 242.6 211.5z"/>
            <path class="state" id="south-carolina" d="M520.1 250.6c0.7-2.2 0.9-6.1 2.1-7.9 0.7-1 1.7-2.3 2.8-3.4 -5.8-4.6-13.4-10.7-14.1-11 -0.4-0.1-3.4 0.3-5.8 0.6 -7.3 1-8.1 1-8.5 0.4l-0.2-0.3c-0.5-0.8-1.4-2.4-2.6-2.8 -1.2-0.4-12.6 0.3-15.9 1 -2.4 0.5-7.6 3-9.9 5.2 0 0-0.7 0.9-0.8 1 -0.2 0.4-0.3 0.7-0.2 1 0.7 2.5 3.3 3.5 4.5 4 0.7 0.3 1.5 1.2 2.5 2.4 1.2 1.4 2.7 3.2 4.4 4.2 1.5 0.9 3.7 3.2 5.8 5.4 2 2.1 4.2 4.4 5.3 4.9 2.6 1.3 5.3 6.2 5.3 7.1 0.1 0.1 0.8 0.7 1.2 1 0.9 0.7 1.8 1.4 2 2.1 0 0.1 0.1 0.3 0.1 0.5 0.3 1.2 0.8 3.7 2.4 3.7 0.8 0 1.7 0 2.7 0 2.9-4.3 8.1-9.7 10.2-12C516.1 255.3 519.3 252.8 520.1 250.6z"/>
            <path class="state" id="minnesota" d="M299.3 34.5c0.7 5.4 1.8 13.1 2.6 15.5 1.1 3.3 2 13.6 2.3 17.9 0.1 0.8 0.1 1.4 0.2 1.7 0.1 0.8 0.8 3.3 1.4 5.2 0.7 2.4 1 3.4 1 3.8 0 0.8-0.4 1.7-0.5 1.8 -1 1.3-2.5 3.6-2.4 4.6 0.1 0.6 1.4 1.7 2.3 2.5 1.3 1.1 2 1.8 2 2.4 -0.1 0.7 0 18.3 0 25.7l54.9-2.1c-0.5-1.9-1.2-5.1-2.6-6 -0.9-0.6-2.5-1.9-4.1-3.3 -2.4-2-5-4.3-6.3-4.8 -0.4-0.1-0.7-0.3-1-0.4 -1.6-0.6-2.7-1-3.1-2.6 -0.1-0.7-0.2-2.3-0.2-4.1 0-2.3-0.1-6.2-0.5-6.7 -0.4-0.5-1.8-2.2-1.7-4 0.1-0.9 0.6-1.7 1.4-2.3 1-0.7 2-1.2 2.8-1.5 1.4-0.6 1.9-0.9 1.9-1.8 0-5.9 0.4-9.1 1.1-9.6 0.3-0.2 2.2-1.3 4.6-2.5 1.3-2 3.6-4.7 6.4-7.5 4.9-4.9 4.8-4.8 7.2-5.5 2.4-0.7 7.1-3.2 6.9-3.8 -0.2-0.6-2.5-0.8-3.5-1.5 -1-0.7-3.2-0.6-4.7-0.2 -1.5 0.3-3 1-3.6 0s-0.8-1.9-1.7-1.8c-0.9 0.1-2.7 3.6-4.1 3.3s-4.8-1.7-6.2-2.9c-1.3-1.2-2.4-2.1-3.5-1.5 -1.2 0.6-2.8-1-4.2-2.2 -1.4-1.2-3.7-1.7-5.4-1.2 -1.7 0.5-1.7 1.5-3.5 1.5 -1.8 0-3.6-0.9-4.6-1.3 -1-0.4-4.8-0.3-5.4-1.3s-1.7-6.4-3.2-7.9 -2.5-1.5-2.7-0.8 0 4.9 0 4.9S312.1 34.5 299.3 34.5z"/>
            <path class="state" id="montana" d="M112.7 29.9c0.3 1.1 0.5 2.6 0.6 4.2 0.3 2.5 0.5 5.3 1.4 6.2 0.9 0.9 2.1 2.8 3.3 4.7 0.9 1.4 1.7 2.8 2.4 3.7 1.5 1.9 5.7 3.8 5.8 3.8l0.5 0.2 -0.3 0.5c0 0.1-4 6.8-4.4 11.5 -0.1 1.2-0.2 2.1-0.3 2.9 -0.1 1-0.2 1.8-0.1 2 0 0 0.1 0 0.3 0 0.4 0 1.2-0.2 1.9-0.4 1.5-0.5 3.1-0.9 3.9-0.1 0.3 0.3 0.5 1.3 1.1 4.2 1 4.7 2.8 13.4 5.4 14 3.7 0.9 16.6 1.5 19.1 1.6l1.4-6 0.5 0.1c0.2 0 23.6 3.8 38.5 5 13.6 1.2 30.6 2 33.8 2.2l3.7-57.7c-18.1-1.1-37.2-2.9-55.4-5.5 -24-3.5-45.2-7.3-62.4-10.7C112.9 21.4 112.3 28.2 112.7 29.9z"/>
            <path class="state" id="ohio" d="M451.6 131.9c0-0.1 0-0.2 0.1-0.4l-15.1 1.8c0.3 2.3 3.4 28.2 5.1 39.1l2.2-0.5c2.5-0.6 2.9 0.4 3.4 2.1 0.1 0.5 0.3 1 0.6 1.6 0.2 0.5 0.6 1.1 1.1 1.3 0.5 0.1 1-0.3 1.1-0.3l0.2-0.2 0.3 0.1c0 0 2.5 1.1 5.3 1.3 1.7 0.1 2.7-0.6 3.7-1.3 0.7-0.5 1.3-0.9 2.1-1.1 1.3-0.3 2.4 1.1 3.6 2.7 0.8 1 1.8 2.3 2.4 2.3 0.6 0 0.9-0.6 1.3-1.8 0.3-0.8 0.6-1.7 1.3-2.4 0.5-0.5 0.7-1.6 1-2.6 0.3-1.5 0.7-3 1.9-3.7 1.5-0.8 1.8-1.7 1.8-1.8 0.1-0.3 2.2-4.9 4.1-6.4 1.7-1.3 5.3-7.4 5.7-14 0.4-5.3-2.7-19.2-4-24.8 -2.6 1.6-4.9 3.2-5.6 3.9 -1.6 1.7-5 5.4-5.8 5.8 -0.7 0.4-3.9 0-4.7 0.8 -0.8 0.7-2 2-6.3 0.9C454 133.3 451.4 133 451.6 131.9z"/>
            <g class="state" id="michigan">
              <path d="M428.8 74.2c-1 0.1-4.1 3.2-3.8 3.6 0.3 0.4 0.8 2 0.1 2.7 -0.7 0.7-8.6 6.8-9.1 9.7 -0.5 2.9-0.7 6.2-1.2 7.4 -0.5 1.2-2 4.9-1.6 6 0.4 1.2-0.3 5.4 2 8.9 2.3 3.5 2.9 5.8 3.3 8.8 0.3 2-0.8 9-3.6 13.4l37-4.4c0.3-2.1 0.8-5.4 1.9-6.8 1.4-1.8 1.5-4.6 3.3-5 1.8-0.4 2.7-3 2-5.9 -0.7-2.9-1.4-4.4-2.8-8.7 -1.5-4.3-1.1-6.4-3.2-6.8 -2.1-0.4-6.6 6.3-8.2 6.5s-5.5 0.9-3.1-3.4c2.4-4.2 4.1-4.7 4.4-6.9 0.3-2.2 0.2-6.3-0.8-7.4 -0.9-1.1-1.1-1.6-1-2.7 0.1-1-1.4-4.3-5.4-5.6C435 76.5 429.8 74.1 428.8 74.2z"/>
              <path d="M370.5 67c0.6 0.9 1.1 1.7 1.6 2.1 2.3 2.1 10.5 4.6 13.7 4.9 3.4 0.4 9 2.3 10.2 4.6 0.4 0.8 0.7 1.7 1 2.8 0.6 1.7 1.2 3.6 2.2 4.7 0.4 0.4 0.8 0.6 1.3 0.5 1.1-0.1 2.4-1.4 3.1-2.6 0.6-0.9 1.8-1.1 3.1-1.1 0.4-1.7 1.2-3.7 2.9-5.6 2.9-3.1 7.1-4.8 9-5.5 1.9-0.7 6.5-0.3 8 0.2 1.5 0.6 1.5-1.3 3.6-1.3 2.2 0 9.2 1.1 9.7 0 0.5-1.1 2.1-2.7 0.7-3.4 -1.4-0.7-2.1 1.2-2.4 1.3 -0.3 0.1-1.9 0.5-3.1-1.1 -1.2-1.5-1.8-4.8-2.5-4.8 -0.7 0-7.7 0.6-7.7 0.6s0.4-3.5-0.5-4c-0.9-0.6-4.6 0.6-5.3 1.2 -0.7 0.6-5 0.8-5.9 1.4 -0.9 0.6-3 2.9-5 3.8 -2.1 0.9-3.1-0.5-3.8-0.4 -0.7 0-2.3 0.6-3.2 0.4 -0.9-0.2-1.8-3.7-4.1-4.3 -2.3-0.6-5.3-1.1-6.2-0.7s-1-2.7 0.9-4.2c1.8-1.6 4.8-3.8 3.9-4.4 -0.9-0.6-4.2-0.2-5.7 1.5 -1.5 1.7-5 5.3-7.2 7s-6.3 2.3-7.5 3C374.4 64.5 372.4 66 370.5 67z"/>
            </g>
            <path class="state" id="tennessee" d="M454 229L454 229c0.5-0.3 2.5-2.8 2.6-4 0.1-1 1.7-1.5 4-2.3 2.2-0.7 4.9-1.6 6.2-3 2.8-2.8 7.6-6.3 10.1-7.4 1.6-0.7 3-4.4 3.6-6.5 -9.3 1.3-17.6 2.4-19.4 2.6 -3.9 0.5-52.4 5.1-53.5 5.1 -0.1 0.1-0.2 0.9-0.2 1.3 0 0.5-0.1 1.1-0.2 1.5 -0.3 0.9-4.9 1.2-13.5 0.8 -0.3 0-0.6-0.2-0.9-0.5 -0.1-0.1-0.1-0.2-0.2-0.4 -0.5-0.4-1.1-0.8-1.4-0.7 -0.2 0.1-0.4 0.5-0.4 1.2 -0.2 3-2 6.7-2.7 8.1 0.3 0.1 0.6 0.3 0.7 0.6 0.3 0.9-0.6 2.7-2 5.5 -0.9 1.8-2 3.8-2 4.6 0 1.1-1 2.6-2 4.1l70.8-6.3C453.7 233.4 453.4 229.3 454 229z"/>
            <path class="state" id="north-carolina" d="M553.8 206.1c0-2.7-2.7-8.5-4-10.2 -0.4-0.6-0.6-1.3-0.6-1.9 -0.8-0.2-1.5-0.4-2.2-0.3 -2.2 0.3-14.3 2.9-24.1 5 -6 1.3-11.1 2.4-13.3 2.8 -3.2 0.6-16.2 2.5-28 4.1 -0.4 1.5-2 6.6-4.3 7.6 -2.4 1-7.1 4.5-9.8 7.2 -1.5 1.6-4.4 2.5-6.6 3.3 -1.3 0.4-3.2 1.1-3.3 1.5 -0.2 1.4-2.1 4-3 4.7 0 0.5 0 3.4 0 3.4l12.4-1.1c2.4-2.6 8.5-5.3 10.7-5.8 3-0.7 14.8-1.6 16.4-1 1.5 0.5 2.6 2.3 3.1 3.2l0.1 0.1c0.8 0.1 4.8-0.4 7.5-0.8 4.2-0.6 5.8-0.8 6.3-0.6 0.9 0.3 10.5 8.1 14.6 11.3 0.6-0.5 1.3-0.9 2.1-1 2.4-0.6 5.8-1.2 5.9-2.9 0.2-1.8 1.7-6.9 5-9.5 3.3-2.6 11.2-6.7 10.9-7.7 -0.3-1-4.4-2.2-3-5.3C548.2 209.2 553.9 208.8 553.8 206.1z"/>
          </g>
        </svg>
      </div>
    </div>
  );
};

export default Map;
