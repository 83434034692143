import React, { useEffect } from 'react';
import Header from '../components/Header';
import Banner from '../components/Banner';
import Categorys from '../components/Categorys';
import FeatureProducts from '../components/products/FeatureProducts';
import Footer from '../components/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { get_products } from '../store/reducers/homeReducer';
import LocationSearchBar from '../components/LocationSearchBar';
import ContactForm from '../components/ContactForm';
import Faq from '../components/Faq';
import Map from '../components/Map';
import AffiliateButton from '../components/AffiliateButtons'; // Import AffiliateButton

const Home = () => {
  const dispatch = useDispatch();
  const { products, banners, categorys } = useSelector((state) => state.home);

  useEffect(() => {
    dispatch(get_products());
  }, []);

  return (
    <div className="w-full">
      <Header />
      <Banner products={products} banners={banners} />
      <LocationSearchBar />

      {categorys.length > 0 ? <Categorys categorys={categorys} /> : null}
      <div className="py-[0px]">
        {products.length > 0 ? <FeatureProducts products={products} /> : null}
      </div>
      <Map />
      <ContactForm />
      <Faq />
      <Footer />
    </div>
  );
};

export default Home;
